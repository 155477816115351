import React, { useEffect, useState } from "react";
import { Typography, Button, message, Spin, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { PostData } from "../services/Api";
import { endPoints } from "../services/EndPoints";
import Cookies from "js-cookie";
import "./LoginAndVerifyOtpScreenStyle.css";
import { useLocation } from "react-router-dom";
import { InputOTP } from "antd-input-otp";
import quanergyIcon from "../assets/quanergyIcon.webp";
const { Text } = Typography;

const VerifyOtpScreen = () => {
  const [loading, setLoading] = useState(false);
  const [OTP, setOTP] = useState([]);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [OTPError, setOTPError] = useState("");
  const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
  const storedTimer = localStorage.getItem("otpTimer");
  const initialTimer =storedTimer !== 0 && storedTimer != null ? storedTimer : 60;
  const [timer, setTimer] = useState(initialTimer);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  localStorage.setItem("otpTimer", timer);
  useEffect(() => {
    if (!email) {
      navigate("/login");
    }
  }, [email]);

  useEffect(() => {
    const isInitialRender = storedTimer === null || storedTimer <= 1;
    if (!isInitialRender) {
      setResendButtonDisabled(true);
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(intervalId);
            setResendButtonDisabled(false);
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
  }, []);

  const handleOTPVerification = async () => {
    setOTPError("");
    const OtpString = OTP.join("");
    if (OTP.length !== 6 || !allFieldsFilled || OTP.includes("")) {
      setOTPError("OTP is required");
    } else {
      try {
        setLoading(true);
        const result = await PostData(endPoints.verifyOtp, {
          email: email,
          otp: OtpString,
        });

        if (result) {
          const { token, companyName, name, email, userId, refresh } = result;
          Cookies.set("authToken", token);
          Cookies.set("refreshToken", refresh);
          let profileData = {
            userId,
            companyName,
            name,
            email,
          };
          Cookies.set("profileData", JSON.stringify(profileData));
        }
        localStorage.setItem("otpTimer", 0);
        setLoading(false);
        window.location.href = "/homescreen";
      } catch (errors) {
        setLoading(false);
        const error =
          errors.error ||
          "Unable to connect with the server, please try again after some time";
        message.error(error);
      }
    }
  };

  const handleResendOTP = async () => {
    try {
      setLoading(true);
      const result = await PostData(endPoints.resendOtp, {
        email: email,
      });

      message.success(result.message);
      setLoading(false);
      setTimer(60);
      setResendButtonDisabled(true);

      // Start the timer countdown
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            // When the timer reaches 0, enable the resend button
            setResendButtonDisabled(false);
            clearInterval(intervalId);
          }

          return prevTimer - 1;
        });
      }, 1000);
    } catch (errors) {
      setLoading(false);
      const error =
        errors.error ||
        "Unable to connect with the server, please try again after some time";
      message.error(error);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedTime =
      minutes.toString().padStart(2, "0") +
      ":" +
      remainingSeconds.toString().padStart(2, "0");
    return formattedTime;
  };

  return (
    <>
      <div
        className="loader-container"
        style={{ display: loading ? "flex" : "none" }}
      >
        <Spin size="large" tip="Loading">
          <span></span>
        </Spin>
      </div>

      <div
        className="box-outer-container"
        style={{ padding: "30px 0px 0px 0px" }}
      >
        <div className="box-container" style={{ gap: "32px" }}>
          <div className="box-title-outer-container">
            <Image
              height={"120px"}
              width={"378.325px"}
              className="box-title-image"
              src={quanergyIcon}
              preview={false}
            />
            <Text className="box-title-imagesub-text">
              Sensor Coverage Tool
            </Text>
          </div>

          <div className="form-container">
            <div className="form-input-container">
              <Text className="box-title-text" style={{ fontWeight: "normal" }}>
                Please enter the OTP sent to <strong>{email}</strong>
              </Text>
              <div
                className="input-field-container"
                style={{ alignItems: "center" }}
              >
                <InputOTP
                  className={`input-field ${OTPError ? "error-input" : ""}`}
                  autoFocus
                  inputType="numeric"
                  length={6}
                  onChange={(otpValue) => {
                    setOTP(otpValue);
                  }}
                  value={OTP}
                  autoSubmit={() => {
                    setAllFieldsFilled(true);
                  }}
                  onPressEnter={handleOTPVerification}
                />

                {OTPError && <Text type="danger">{OTPError}</Text>}
              </div>

              <div className="submit-button-outer-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                  block
                  onClick={handleOTPVerification}
                >
                  Submit
                </Button>

                <Button
                  type="link"
                  className="navigation-link"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Back to login
                </Button>
              </div>

              <div className="form-links-container">
                <Text className="text">Didn’t receive a code?</Text>
                <div className="resend-button-outer-layout">
                  <Button
                    type="link"
                    className="navigation-link"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    onClick={handleResendOTP}
                    disabled={resendButtonDisabled}
                  >
                    Resend
                  </Button>
                  <Text className="timer-text">
                    {resendButtonDisabled ? `${formatTime(timer)}` : null}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtpScreen;
