let currentlyActiveButton=null
function customizeButtons(button, index, fileName) {
    
    let tooltips = ['View', 
    'Backward', 'Forward', 
    'pans', 
    ' Zoom', 'Save PDF', 'Save PNG', 'Save SVG'];

    button.style.cursor = "pointer";

    let tooltipText = document.createElement('span');
    tooltipText.textContent = tooltips[index];  
    tooltipText.classList.add('tooltip');
    button.appendChild(tooltipText);
    button.classList.add('fa-fa-button');

    // Add click event listener for buttons at positions three and four
    if (index === 3 || index === 4) {
        button.addEventListener('click', function () {
            if (currentlyActiveButton === button) {
                // Deactivate the clicked button if it's already active
                button.classList.remove('active');
                button.style.backgroundColor = "#e9ecef";
                button.style.color = "#495057";
                currentlyActiveButton = null; // No active button
            } else {
                if (currentlyActiveButton) {
                    // Deactivate the currently active button
                    currentlyActiveButton.classList.remove('active');
                    currentlyActiveButton.style.backgroundColor = "#e9ecef";
                    currentlyActiveButton.style.color = "#495057";
                }

                // Activate the clicked button
                button.classList.add('active');
                button.style.backgroundColor = "#495057";
                button.style.color = 'white';

                // Update the currently active button
                currentlyActiveButton = button;
            }
        });
    }
    
    if (index === 5 || index === 6 || index === 7) {
        button.removeAttribute('id');
        // Set a new ID for the buttons at positions five, six, and seven
        button.setAttribute('id', fileName);
    }
}

export function removeCanvasElement(divElementID,fileName) {

    let chartArea = document.getElementById(divElementID);
    let div_elements = document.querySelectorAll('[id^="matplotlib"]');
    let index = div_elements.length - 5;
    if (div_elements.length !== 0 && index >= 0) {
        let selectedElement = div_elements[1]; 
        chartArea.appendChild(selectedElement);

        let matplotlibButtons = selectedElement.querySelectorAll('.matplotlib-toolbar-button');
        
        matplotlibButtons.forEach((button, index) => {
            customizeButtons(button, index, fileName);
        });
    }
}
