//Login.js
import React, { useState } from "react";
import { Typography, Input, Button, message, Spin, Image } from "antd";
import "./LoginAndVerifyOtpScreenStyle.css";
import { PostData } from "../services/Api";
import {endPoints} from '../services/EndPoints'
import { useNavigate } from "react-router-dom";
import quanergyIcon from "../assets/quanergyIcon.webp";
const { Text, Link } = Typography;

const LoginScreen = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  const handleSignIn = async () => {
    setLoading(true);
    setEmailError("");

    if (email.trim() === "") {
      setEmailError("Email is required");
      setLoading(false);
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Email is invalid");
      setLoading(false);
    } else {
      try {
        const result = await PostData(endPoints.login, {
          email: email,
        });

        setLoading(false);
        message.success(result.message);
        navigate("/verifyotp", { state: { email: email } });
      } catch (errors) {
        setLoading(false);
        const error =
          errors.error ||
          "Unable to connect with the server, please try again after some time";
        message.error(error);
      }
    }
  };

  return (
    <>
      <div
        className="loader-container"
        style={{ display: loading ? "flex" : "none" }}
      >
        <Spin size="large" tip="Loading">
          <span></span>
        </Spin>
      </div>
      <div className="box-outer-container">
        <div className="box-container">
          <div className="box-title-outer-container">
            <Image
              height={"120px"}
              width={"378.325px"}
              className="box-title-image"
              src={quanergyIcon}
              preview={false}
            />
            <Text className="box-title-imagesub-text">
              Sensor Coverage Tool
            </Text>
          </div>

          <div className="form-container">
            <div className="input-field-container">
              <Text className="box-title-text">Log in to your account</Text>
              <Text className="email-label-text">Email</Text>

              <Input
                className={`input-field ${emailError ? "error-input" : ""}`}
                maxLength={50}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setEmailError("");
                }}
                onPressEnter={handleSignIn}
                placeholder="Email"
              />
              {emailError && <Text type="danger">{emailError}</Text>}
            </div>

            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              onClick={handleSignIn}
              block
            >
              Send OTP
            </Button>
          </div>
          <Text className="box-footer-text">
            For assistance, contact{" "}
            <Link
              target="_blank"
              href="mailto:support@quanergy.com"
              className="box-footer-text"
            >
              support@quanergy.com
            </Link>
          </Text>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
