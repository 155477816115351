import React from "react";
import { Button, Typography, InputNumber } from "antd";
import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import "./SensorObstacleComponent.css";
const { Text } = Typography;

const ObstacleComponent = ({
  obstacleName,
  obstacleData,
  isActive,
  onClick,
  onDelete,
  onInputChange,
  toggleFlag,
}) => {
  const toggleObstacleElement = (status) => {
    onClick(status); // Toggle the active sensor when clicked
  };

  const isValidNumber = (value, min, max, precision) => {
    if (value === "") {
      return true; // Allow an empty value
    }

    const numericValue = Number(value);

    // Check if the numeric value is within the specified range
    if (numericValue < min || numericValue > max) {
      return false;
    }

    // Check if the numeric value has the correct precision
    const valueString = String(value);
    const decimalIndex = valueString.indexOf(".");
    const decimalPlaces =
      decimalIndex === -1 ? 0 : valueString.length - decimalIndex - 1;

    return decimalPlaces <= precision;
  };

  const handlePositionFieldOnKeyDown = (event, minValue, maxValue) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      "-",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    const inputValue = event.target.value;
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    if (inputValue.startsWith("0") && event.key === "0" && selectionStart === 1) {
      event.preventDefault();
    }

    // Handle Backspace and Delete keys
    if (event.key === "Backspace" || event.key === "Delete") {
      // Allow the default behavior for Backspace and Delete keys if no selection
      if (selectionStart === selectionEnd) {
        return;
      }
    }

    // If the user is moving the cursor (ArrowLeft or ArrowRight), allow it
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      return;
    }

    if (event.key === "." && inputValue.includes(".")) {
      event.preventDefault();
      return;
    }

    // Check if the pressed key is a minus sign
    if (event.key === "-") {
      // If the cursor is not at the beginning or there's already a minus sign, prevent entry
      if (selectionStart !== 0 || inputValue.includes("-")) {
        event.preventDefault();
        return;
      }
    }

    if (inputValue.includes("-") && selectionStart === 0) {
      event.preventDefault();
      return;
    }

    if (event.key === "0" && selectionStart > 0) {
      // If there's already a zero at the current cursor position, prevent entry
      if (
        inputValue.charAt(selectionStart - 1) === "-" &&
        inputValue.charAt(selectionStart - 2) !== "."
      ) {
        event.preventDefault();
        return;
      }
    }

    // Replace the selected part of the input value with the pressed key
    const newValue =
      inputValue.substring(0, selectionStart) +
      event.key +
      inputValue.substring(selectionEnd);

    // Check if the new value is a valid number within the allowed range and precision
    if (!isValidNumber(newValue, minValue, maxValue, 2)) {
      event.preventDefault();
    }
  };

  const handleHeightFieldOnKeyDown = (event, minValue, maxValue) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    const inputValue = event.target.value;
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    if (inputValue.startsWith("0") && event.key === "0" && selectionStart === 1) {
      event.preventDefault();
    }

    // Handle Backspace and Delete keys
    if (event.key === "Backspace" || event.key === "Delete") {
      // Allow the default behavior for Backspace and Delete keys if no selection
      if (selectionStart === selectionEnd) {
        return;
      }
    }

    // If the user is moving the cursor (ArrowLeft or ArrowRight), allow it
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      return;
    }

    if (event.key === "." && inputValue.includes(".")) {
      event.preventDefault();
      return;
    }
    // Replace the selected part of the input value with the pressed key
    const newValue =
      inputValue.substring(0, selectionStart) +
      event.key +
      inputValue.substring(selectionEnd);

    // Check if the new value is a valid number within the allowed range and precision
    if (!isValidNumber(newValue, minValue, maxValue, 2)) {
      event.preventDefault();
    }
  };

  const handleRotationFiledOnKeyDown = (event, minValue, maxValue) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      "-",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    const inputValue = event.target.value;
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    if (inputValue.startsWith("0") && event.key === "0" && selectionStart === 1) {
      event.preventDefault();
    }

    // Handle Backspace and Delete keys
    if (event.key === "Backspace" || event.key === "Delete") {
      // Allow the default behavior for Backspace and Delete keys if no selection
      if (selectionStart === selectionEnd) {
        return;
      }
    }

    // If the user is moving the cursor (ArrowLeft or ArrowRight), allow it
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      return;
    }

    // Replace the selected part of the input value with the pressed key
    const newValue =
      inputValue.substring(0, selectionStart) +
      event.key +
      inputValue.substring(selectionEnd);

    if (event.key === "." && inputValue.includes(".")) {
      event.preventDefault();
      return;
    }

    // Check if the pressed key is a minus sign
    if (event.key === "-") {
      // If the cursor is not at the beginning or there's already a minus sign, prevent entry
      if (selectionStart !== 0 || inputValue.includes("-")) {
        event.preventDefault();
        return;
      }
    }

    if (inputValue.includes("-") && selectionStart === 0) {
      event.preventDefault();
      return;
    }

    if (event.key === "0" && selectionStart > 0) {
      // If there's already a zero at the current cursor position, prevent entry

      if (
        inputValue.charAt(selectionStart - 1) === "-" &&
        inputValue.charAt(selectionStart - 2) !== "."
      ) {
        event.preventDefault();
        return;
      }
    }

    // Check if the new value is a valid number within the allowed range and precision
    if (!isValidNumber(newValue, minValue, maxValue, 1)) {
      event.preventDefault();
    }
  };

  return (
    <>
      {!isActive ? (
        <div className="sensor-outer-layout">
          <div className="sensor-layout">
            <Text className="sensor-field-name">{obstacleName}</Text>

            <Button
              type="link"
              className="buttons-outer-layout"
              icon={<DeleteOutlined />}
              onClick={() => onDelete()}
              danger
            ></Button>
            <Button
              type="default"
              className="buttons-outer-layout"
              icon={<DownOutlined />}
              onClick={() => toggleObstacleElement(true)}
            ></Button>
          </div>
        </div>
      ) : (
        <div className="sensor-obstacle-fields-outer-layout">
          <div className="sensor-obstacle-fields-column-layout">
            <div className="sensor-field-name-layout">
              <Text className="sensor-field-name">{obstacleName}</Text>
              <Button
                type="link"
                className="buttons-outer-layout"
                icon={<DeleteOutlined />}
                onClick={() => onDelete()}
                danger
              ></Button>
              <Button
                type="default"
                className="buttons-outer-layout"
                icon={<UpOutlined />}
                onClick={() => toggleObstacleElement(false)}
              ></Button>
            </div>
          </div>
          <div className="sensor-obstacle-fields-column-layout">
            <Text className="sensor-field-label-name">Position</Text>
            <div className="fields-row-outer-layout">
              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">X</Text>
                <InputNumber
                  className="input-fields-value"
                  min={-10000.0}
                  max={10000.0}
                  step={1} // Set the step to control decimal places
                  precision={2} // Set the precision to restrict decimal places
                  value={obstacleData?.center[0]}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -10000.0 &&
                      value <= 10000.0
                    ) {
                      onInputChange("center", [
                        value,
                        obstacleData.center[1],
                        obstacleData.center[2],
                      ]);
                    }
                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue =
                      parseFloat(obstacleData?.center[0]) - value;

                    const newValue =
                      parseFloat(obstacleData?.center[0]) - currentValue;

                    if (newValue >= -10000.0 && newValue <= 10000.0) {
                      onInputChange("center", [
                        newValue,
                        obstacleData.center[1],
                      ]);
                    }
                  }}
                  onKeyDown={(event) =>
                    handlePositionFieldOnKeyDown(event, -10000.0, 10000.0)
                  }
                />
                <Text className="sensor-field-label-value">m</Text>
              </div>

              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">Y</Text>

                <InputNumber
                  className="input-fields-value"
                  min={-10000.0}
                  max={10000.0}
                  step={1} // Set the step to control decimal places
                  precision={2} // Set the precision to restrict decimal places
                  value={obstacleData?.center[1]}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -10000.0 &&
                      value <= 10000.0
                    ) {
                      onInputChange("center", [
                        obstacleData?.center[0],
                        value,
                        obstacleData.center[2],
                      ]);
                    }
                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue =
                      parseFloat(obstacleData?.center[1]) - value;

                    const newValue =
                      parseFloat(obstacleData?.center[1]) - currentValue;

                    if (newValue >= -10000.0 && newValue <= 10000.0) {
                      onInputChange("center", [
                        obstacleData?.center[0],
                        newValue,
                      ]);
                    }
                  }}
                  onKeyDown={(event) =>
                    handlePositionFieldOnKeyDown(event, -10000.0, 10000.0)
                  }
                />
                <Text className="value-text">m</Text>
              </div>
            </div>
          </div>

          <div className="sensor-obstacle-fields-column-layout">
            <Text className="sensor-field-label-name">Size</Text>
            <div className="fields-row-outer-layout">
              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">W</Text>
                <InputNumber
                  className="input-fields-value"
                  min={-10000.0}
                  max={10000.0}
                  step={1}
                  precision={2}
                  value={obstacleData.width}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -10000.0 &&
                      value <= 10000.0
                    ) {
                      onInputChange("width", value);
                    }
                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue = parseFloat(obstacleData.width) - value;

                    const newValue =
                      parseFloat(obstacleData.width) - currentValue;

                    if (newValue >= -10000.0 && newValue <= 10000.0) {
                      onInputChange("width", newValue);
                    }
                  }}
                  onKeyDown={(event) =>
                    handlePositionFieldOnKeyDown(event, -10000.0, 10000.0)
                  }
                />

                <Text className="sensor-field-label-value">m</Text>
              </div>

              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">H</Text>
                <InputNumber
                  className="input-fields-value"
                  min={0.0}
                  max={10000.0}
                  step={1}
                  precision={2}
                  value={obstacleData.height}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      value >= 0.0 &&
                      value <= 10000.0
                    ) {
                      onInputChange("height", value);
                    }
                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue = parseFloat(obstacleData.width) - value;

                    const newValue =
                      parseFloat(obstacleData.width) - currentValue;

                    if (newValue >= 0.0 && newValue <= 10000.0) {
                      onInputChange("height", newValue);
                    }
                  }}
                  onKeyDown={(event) => handleHeightFieldOnKeyDown(event, 0.0, 10000.0)}
                />
                <Text className="sensor-field-label-value">m</Text>
              </div>
              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">L</Text>
                <InputNumber
                  className="input-fields-value"
                  min={-10000.0}
                  max={10000.0}
                  step={1}
                  precision={2}
                  value={obstacleData.length}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -10000.0 &&
                      value <= 10000.0
                    ) {
                      onInputChange("length", value);
                    }
                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue = parseFloat(obstacleData.width) - value;

                    const newValue =
                      parseFloat(obstacleData.width) - currentValue;

                    if (newValue >= -10000.0 && newValue <= 10000.0) {
                      onInputChange("length", newValue);
                    }
                  }}
                  onKeyDown={(event) =>
                    handlePositionFieldOnKeyDown(event, -10000.0, 10000.0)
                  }
                />
                <Text className="sensor-field-label-value">m</Text>
              </div>
            </div>
          </div>
          <div className="sensor-obstacle-fields-column-layout">
            <Text className="sensor-field-label-name">Rotation</Text>
            <div className="fields-row-outer-layout">
              <div className="fields-data-outer-layout">
                <InputNumber
                  className="input-fields-value"
                  min={-360.0}
                  max={360.0}
                  step={1}
                  precision={1}
                  value={obstacleData.rotation}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -360.0 &&
                      value <= 360.0
                    ) {
                      onInputChange("rotation", value);
                    }
                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue = parseFloat(obstacleData.width) - value;

                    const newValue =
                      parseFloat(obstacleData.width) - currentValue;

                    if (newValue >= -360.0 && newValue <= 360.0) {
                      onInputChange("rotation", newValue);
                    }
                  }}
                  onKeyDown={(event) =>
                    handleRotationFiledOnKeyDown(event, -360.0, 360.0)
                  }
                />
                <Text className="sensor-field-label-value">°</Text>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ObstacleComponent;
