import React, { useState, useEffect } from "react";
import { Button, Modal, Typography, InputNumber, Spin, message } from "antd";
import "./ConfigurationImageReference.css";
import graphScripts from "../python/ReferenceImageGraphPlot.py";
import ConfigureOriginAxisMeasure from "./ConfigureOriginAxisMeasure";
import { useSelector } from "react-redux";
import { selectLidarInfo } from "../redux/LidarSlice";
import { removeCanvasElement } from "../utils/removeCanvasElement";

const { Text } = Typography;

function ConfigurationImageReference({
  visible,
  onCancel,
  onOk,
  image,
  loadPyodide,
  fileName,
}) {
  const lidarInfo = useSelector(selectLidarInfo);
  const [output, setOutput] = useState(false);
  const [toggleGraph, setToggleGraph] = useState(true);
  const [loading, setLoading] = useState(true);
  const [newModelTitle, setNewModelTitle] = useState();
  const [settingId, setSettingId] = useState();
  const [
    configureOriginAxisMeasureModalVisible,
    setConfigureOriginAxisMeasureModalVisible,
  ] = useState(false);

  const [loc, _loc] = useState({
    origin_x: lidarInfo.plotSettings["reference image"].origin.x || 0,
    origin_y: lidarInfo.plotSettings["reference image"].origin.y || 0,
    scale: lidarInfo.plotSettings["reference image"].scale || 1,
    rotation: lidarInfo.plotSettings["reference image"].rotation || 0,
    alpha: lidarInfo.plotSettings["reference image"].alpha || 1,
  });

  const [newLoc, setNewLocation] = useState({
    origin_x: 0,
    origin_y: 0,
    scale: 1,
    rotation: 0,
    alpha: 1,
  });

  
  useEffect(() => {
    setLoading(true);

    let divElements = document.querySelectorAll('div[id^="matplotlib"]');
    divElements.forEach(function (element) {
      element.parentNode.removeChild(element);
    });

    const run = async (locPoint) => {
      let scriptText = await (await fetch(graphScripts)).text();
      await runScript(scriptText, image, locPoint);
      setOutput(true);
      setLoading(false);
    };

    run(loc);
  }, [toggleGraph]);
  
  const handleInputChange = (key, value) => {
    _loc((prevLoc) => ({
      ...prevLoc,
      [key]: value,
    }));
  };

  const handleInputBlur = () => {
    setToggleGraph(!toggleGraph);
  };

  const runScript = async (code, imageURL, locPoint) => {
    loadPyodide
      .then((loadedPyodides) => {
        let pyodide = loadedPyodides;
        let image_json = locPoint;
        let image_data_url = imageURL;
        pyodide.globals.set("image_data_url", image_data_url);
        pyodide.globals.set("image_json", image_json);
        pyodide.runPython(code);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const handleConfigureOriginAxisMeasureModalOk = (length) => {
    if (newLoc) {
      if (settingId === 1) {
        _loc((prevLoc) => ({
          ...prevLoc,
          origin_x: parseInt(newLoc.origin_x),
          origin_y: parseInt(newLoc.origin_y),
        }));
      } else if (settingId === 2) {
        _loc((prevLoc) => ({
          ...prevLoc,
          rotation: newLoc.rotation,
        }));
      } else if (settingId === 3 && length) {
        const newScaleValue = length / newLoc.scale;
        if (newScaleValue < 0.01) {
          _loc((prevLoc) => ({
            ...prevLoc,
            scale: 0.01,
          }));
        } else if (newScaleValue > 100.0) {
          _loc((prevLoc) => ({
            ...prevLoc,
            scale: 100.0,
          }));
        } else {
          _loc((prevLoc) => ({
            ...prevLoc,
            scale: length / newLoc.scale,
          }));
        }
      }
    }

    setConfigureOriginAxisMeasureModalVisible(false);
    setOutput(false);
    setToggleGraph(!toggleGraph);
  };

  const handleConfigureOriginAxisMeasureModalCancel = () => {
    setConfigureOriginAxisMeasureModalVisible(false);
    setOutput(false);
    setToggleGraph(!toggleGraph);
  };

  const handleButtonClick = async (title, setID) => {
    setSettingId(setID);

    setNewModelTitle(title);
    setConfigureOriginAxisMeasureModalVisible(true);
  };

  window.handleRefrenceImageLocPoint = (newjson) => {
    const validJsonString = newjson.replace(/'/g, '"');
    const parsedObject = JSON.parse(validJsonString);
    setNewLocation(parsedObject);
  };

  const isValidNumberForDecimalValue = (value, min, max, precision) => {
    if (value === "") {
      return true;
    }

    const numericValue = Number(value);
    if (numericValue < min || numericValue > max) {
      return false;
    }

    const valueString = String(value);
    const decimalIndex = valueString.indexOf(".");
    const decimalPlaces =
      decimalIndex === -1 ? 0 : valueString.length - decimalIndex - 1;

    return decimalPlaces <= precision;
  };

  return (
    <>
      <Modal
        title={
          <div className="modal-title-overlay">
            <Text>Configure Reference Image Transforms</Text>
          </div>
        }
        open={visible}
        onCancel={onCancel}
        onOk={() => onOk(loc)}
        okText="OK"
        cancelText="Cancel"
        className="model-layout"
        cancelButtonProps={{ style: { display: "none" } }}
        maskClosable={false}
      >
        {loading && (
          <div
            className="loader-container"
            style={{ display: loading ? "flex" : "none" }}
          >
            <Spin size="large" tip="Loading">
              <span></span>
            </Spin>
          </div>
        )}

        <div className="model-content-overlay">
          <div className="model-graph-plot-outer-layout">
            <div
              className="model-graph-plot-area-layout"
              id="configure-image-graph-plot-area"
            >
              {output === false
                ? null
                : removeCanvasElement(
                    "configure-image-graph-plot-area",
                    fileName
                  )}
            </div>
          </div>

          <div className="model-content-sidebar">
            <div className="sidebar-content-outer-layout">
              <div className="content-element-layout">
                <Text className="content-element-text">Position</Text>
                <Button
                  className="content-element-button"
                  onClick={() => handleButtonClick("Select Image Origin", 1)}
                >
                  Pick Origin
                </Button>
              </div>
              <div className="content-element-layout">
                <Text className="content-element-label">X Offset</Text>
                <InputNumber
                  min={-2000}
                  max={2000}
                  value={loc.origin_x}
                  className="input-number-style"
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -2000 &&
                      value <= 2000
                    ) {
                      handleInputChange("origin_x", value);
                    }

                    handleInputBlur();
                  }}
                  step={1}
                  onStep={(value) => {
                    const currentValue = parseInt(loc.origin_x) - value;
                    const newValue = parseInt(loc.origin_x) - currentValue;
                    if (newValue >= -2000 && newValue <= 2000) {
                      handleInputChange("origin_x", newValue);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      const inputValue = event.target.value.trim();
                      const numericValue = parseInt(inputValue);
                      if (
                        !isNaN(numericValue) &&
                        numericValue >= -2000 &&
                        numericValue <= 2000
                      ) {
                        handleInputChange("origin_x", numericValue);
                        handleInputBlur();
                      }
                    } else {
                      const allowedKeys = [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "-",
                        "Backspace",
                        "Delete",
                        "ArrowLeft",
                        "ArrowRight",
                        "Home",
                        "End",
                      ];

                      const currentValue = event.target.value;

                      if (event.key === "-" && event.target.selectionStart !== 0) {
                        event.preventDefault();
                      }

                      if (currentValue === "0" && event.key === "0") {
                        event.preventDefault();
                      }

                      if (currentValue === "-" && event.key === "0") {
                        event.preventDefault();
                      }

                      if (
                        (currentValue.startsWith("0") ||
                          currentValue.startsWith("-")) &&
                        event.key === "0" &&
                        event.target.selectionStart === 1
                      ) {
                        event.preventDefault();
                      }

                      const numericValue = parseInt(currentValue + event.key);
                      if (
                        (numericValue === 0 || numericValue === -0) &&
                        (event.key === "0" || event.key === "-")
                      ) {
                        event.preventDefault();
                      }

                      if (numericValue < -2000 || numericValue > 2000) {
                        event.preventDefault();
                      }
                      if (!allowedKeys.includes(event.key)) {
                        event.preventDefault();
                      }
                    }
                  }}
                ></InputNumber>
                <Text className="content-element-units-label">px</Text>
                <Text className="content-element-label">Y Offset</Text>
                <InputNumber
                  min={-2000}
                  max={2000}
                  value={loc.origin_y}
                  className="input-number-style"
                  formatter={(value) => `${parseInt(value, 10)}`}
                  step={1}
                  parser={(value) => parseInt(value, 10)}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -2000 &&
                      value <= 2000
                    ) {
                      handleInputChange("origin_y", value);
                    }

                    handleInputBlur();
                  }}
                  onStep={(value) => {
                    const currentValue = parseInt(loc.origin_y) - value;
                    const newValue = parseInt(loc.origin_y) - currentValue;
                    if (newValue >= -2000 && newValue <= 2000) {
                      handleInputChange("origin_y", newValue);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      const inputValue = event.target.value.trim();
                      const numericValue = parseInt(inputValue);
                      if (
                        !isNaN(numericValue) &&
                        numericValue >= -2000 &&
                        numericValue <= 2000
                      ) {
                        handleInputChange("origin_x", numericValue);
                        handleInputBlur();
                      }
                    } else {
                      const allowedKeys = [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "-",
                        "Backspace",
                        "Delete",
                        "ArrowLeft",
                        "ArrowRight",
                        "Home",
                        "End",
                      ];

                      const currentValue = event.target.value;

                      if (event.key === "-" && event.target.selectionStart !== 0) {
                        event.preventDefault();
                      }

                      if (currentValue === "0" && event.key === "0") {
                        event.preventDefault();
                      }

                      if (currentValue === "-" && event.key === "0") {
                        event.preventDefault();
                      }

                      if (
                        (currentValue.startsWith("0") ||
                          currentValue.startsWith("-")) &&
                        event.key === "0" &&
                        event.target.selectionStart === 1
                      ) {
                        event.preventDefault();
                      }

                      const numericValue = parseInt(currentValue + event.key);
                      if (
                        (numericValue === 0 || numericValue === -0) &&
                        (event.key === "0" || event.key === "-")
                      ) {
                        event.preventDefault();
                      }

                      if (numericValue < -2000 || numericValue > 2000) {
                        event.preventDefault();
                      }
                      if (!allowedKeys.includes(event.key)) {
                        event.preventDefault();
                      }
                    }
                  }}
                ></InputNumber>
                <Text className="content-element-units-label">px</Text>
              </div>
            </div>

            <div className="sidebar-content-outer-layout">
              <div className="content-element-layout">
                <Text className="content-element-text">Rotation</Text>
                <Button
                  className="content-element-button"
                  onClick={() =>
                    handleButtonClick("Draw Direction of X Axis", 2)
                  }
                >
                  Define X axis
                </Button>
              </div>
              <div
                className="content-element-layout"
                style={{ justifyContent: "flex-start" }}
              >
                <Text className="content-element-label">Angle</Text>
                <InputNumber
                  min={0.0}
                  max={360.0}
                  value={loc.rotation}
                  className="input-number-style"
                  step={1}
                  precision={1}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= 0.0 &&
                      value <= 360.0
                    ) {
                      handleInputChange("rotation", value);
                    }

                    handleInputBlur();
                  }}
                  onStep={(value) => {
                    const currentValue = parseFloat(loc.origin_y) - value;
                    const newValue = parseFloat(loc.origin_y) - currentValue;
                    if (newValue >= 0.0 && newValue <= 360.0) {
                      handleInputChange("rotation", newValue);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      const inputValue = event.target.value.trim();
                      const numericValue = parseFloat(inputValue);
                      if (
                        !isNaN(numericValue) &&
                        numericValue >= 0.0 &&
                        numericValue <= 360.0
                      ) {
                        handleInputChange("rotation", numericValue);
                        handleInputBlur();
                      }
                    } else {
                      const allowedKeys = [
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        ".",
                        "Backspace",
                        "Delete",
                        "ArrowLeft",
                        "ArrowRight",
                        "Home",
                        "End",
                      ];

                      if (!allowedKeys.includes(event.key)) {
                        event.preventDefault();
                        return;
                      }

                      const inputValue = event.target.value;
                      const selectionStart = event.target.selectionStart;
                      const selectionEnd = event.target.selectionEnd;

                      if (event.key === "Backspace" || event.key === "Delete") {
                        if (selectionStart === selectionEnd) {
                          return;
                        }
                      }

                      if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                        return;
                      }
                      if (event.key === "Enter") {
                        event.preventDefault();
                      }

                      const newValue =
                        inputValue.substring(0, selectionStart) +
                        event.key +
                        inputValue.substring(selectionEnd);

                      if (
                        newValue.startsWith("0") &&
                        event.key === "0" &&
                        (event.target.selectionStart === 1 ||
                          event.target.selectionStart === 1)
                      ) {
                        event.preventDefault();
                      }

                      if (
                        !isValidNumberForDecimalValue(newValue, 0.0, 360.0, 1)
                      ) {
                        event.preventDefault();
                      }
                    }
                  }}
                ></InputNumber>
                <Text className="content-element-units-label">°</Text>
              </div>
            </div>

            <div className="sidebar-content-outer-layout">
              <div className="content-element-layout">
                <div className="content-element-layout">
                  <Text className="content-element-label">Scale</Text>
                  <InputNumber
                    min={0.01}
                    max={10000.0}
                    value={`${loc.scale * 100}`}
                    step={1}
                    precision={2}
                    className="input-number-style"
                    onBlur={(event) => {
                      const value = Number(event.target.value);
                      if (
                        event.target.value !== "" &&
                        value >= 0.01 &&
                        value <= 10000.0
                      ) {
                        let calculatedValue = value / 100;
                        handleInputChange("scale", calculatedValue);
                      }

                      handleInputBlur();
                    }}
                    onStep={(value) => {
                      const currentValue = parseFloat(loc.origin_y) - value;
                      let newValue = parseFloat(loc.origin_y) - currentValue;
                      newValue = newValue / 100;

                      if (newValue >= 0.01 && newValue <= 10000.0) {
                        handleInputChange("scale", newValue);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        const inputValue = event.target.value.trim();
                        const numericValue = parseFloat(inputValue);
                        if (
                          !isNaN(numericValue) &&
                          numericValue >= -0.01 &&
                          numericValue <= 10000.0
                        ) {
                          handleInputChange("origin_x", numericValue);
                          handleInputBlur();
                        }
                      } else {
                        const allowedKeys = [
                          "0",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          ".",
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                          "Home",
                          "End",
                        ];

                        if (!allowedKeys.includes(event.key)) {
                          event.preventDefault();
                          return;
                        }
                        if (event.key === "Enter") {
                          event.preventDefault();
                        }

                        const inputValue = event.target.value;
                        const selectionStart = event.target.selectionStart;
                        const selectionEnd = event.target.selectionEnd;

                        if (event.key === "Backspace" || event.key === "Delete") {
                          if (selectionStart === selectionEnd) {
                            return;
                          }
                        }

                        if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                          return;
                        }

                        const newValue =
                          inputValue.substring(0, selectionStart) +
                          event.key +
                          inputValue.substring(selectionEnd);

                        if (
                          !isValidNumberForDecimalValue(
                            newValue,
                            0.01,
                            10000.0,
                            2
                          )
                        ) {
                          event.preventDefault();
                        }
                      }
                    }}
                  ></InputNumber>
                  <Text className="content-element-units-label">%</Text>
                </div>

                <Button
                  className="content-element-button"
                  onClick={() => handleButtonClick("Set Measured Distance", 3)}
                >
                  Measure
                </Button>
              </div>
            </div>

            <div className="sidebar-content-outer-layout">
              <div className="content-element-layout">
                <div className="content-element-layout">
                  <Text className="content-element-label">Opacity</Text>
                  <InputNumber
                    min={0}
                    max={100}
                    value={`${loc.alpha * 100}`}
                    className="input-number-style"
                    formatter={(value) => `${parseInt(value, 10)}`}
                    step={1}
                    parser={(value) => parseInt(value, 10)}
                    onBlur={(event) => {
                      const value = parseInt(event.target.value);
                      if (event.target.value !== "" && value >= 0 && value <= 100) {
                        const newValue = value / 100;
                        handleInputChange("alpha", newValue);
                      }

                      handleInputBlur();
                    }}
                    onStep={(value) => {
                      const currentValue = parseInt(loc.origin_y) - value;
                      let newValue = parseInt(loc.origin_y) - currentValue;
                      newValue = newValue / 100;
                      if (newValue >= 0 && newValue <= 100) {
                        handleInputChange("alpha", newValue);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        const inputValue = event.target.value.trim();
                        const numericValue = parseInt(inputValue);
                        if (
                          !isNaN(numericValue) &&
                          numericValue >= 0 &&
                          numericValue <= 100
                        ) {
                          handleInputChange("alpha", numericValue);
                          handleInputBlur();
                        }
                      } else {
                        const allowedKeys = [
                          "0",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                          "Home",
                          "End",
                        ];

                        const value = event.target.value;
                        const selectionStart = event.target.selectionStart;

                        const currentValue = parseInt(event.target.value + event.key);

                        if (
                          isNaN(currentValue) ||
                          currentValue < 0 ||
                          currentValue > 100
                        ) {
                          event.preventDefault();
                        }

                        if (
                          value.startsWith("0") &&
                          event.key === "0" &&
                          selectionStart === 1
                        ) {
                          event.preventDefault();
                        }

                        if (!allowedKeys.includes(event.key)) {
                          event.preventDefault();
                        }
                      }
                    }}
                  ></InputNumber>
                  <Text className="content-element-units-label">%</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {configureOriginAxisMeasureModalVisible && (
        <ConfigureOriginAxisMeasure
          title={newModelTitle}
          visible={configureOriginAxisMeasureModalVisible}
          onOk={handleConfigureOriginAxisMeasureModalOk}
          onCancel={handleConfigureOriginAxisMeasureModalCancel}
          loadPyodide={loadPyodide}
          image={image}
          loc={loc}
          settingId={settingId}
          fileName={fileName}
        />
      )}
    </>
  );
}

export default ConfigurationImageReference;
