import React from 'react';
import AllRoutes from './routing/AllRoutes'

function App() {
  return (
    <div className="App">
      <AllRoutes/>
    </div>
  );
}

export default App;