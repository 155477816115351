import React, { useState, useEffect } from "react";
import { InputNumber, Modal, Spin, message, Typography } from "antd";
import graphScripts from "../python/ReferenceImageOriginAxisMeasure.py";
import "./ConfigurationImageReference.css";
import { removeCanvasElement } from "../utils/removeCanvasElement";

const { Text } = Typography;

function ConfigureOriginAxisMeasure({
  title,
  visible,
  onOk,
  onCancel,
  loadPyodide,
  image,
  settingId,
  fileName,
}) {
  const [output, setOutput] = useState(false);
  const [loading, setLoading] = useState(true);
  const [length, setLength] = useState(100.0);

  const runScript = async (code, imageURL) => {
    loadPyodide
      .then((loadedPyodides) => {
        let pyodide = loadedPyodides;
        let image_data_url = imageURL;
        pyodide.globals.set("settingID", settingId);
        pyodide.globals.set("image_data_url", image_data_url);
        pyodide.runPython(code);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    let divElements = document.querySelectorAll('div[id^="matplotlib"]');
    divElements.forEach(function (element) {
      element.parentNode?.removeChild(element);
    });

    const run = async () => {
      let scriptText = await (await fetch(graphScripts)).text();
      await runScript(scriptText, image, settingId);
      setOutput(true);
      setLoading(false);
    };

    run();
  }, []);

  const isValidNumberForDecimalValue = (value, min, max, precision) => {
    if (value === "") {
      return true;
    }

    const numericValue = Number(value);
    if (numericValue < min || numericValue > max) {
      return false;
    }
    const valueString = String(value);
    const decimalIndex = valueString.indexOf(".");
    const decimalPlaces =
      decimalIndex === -1 ? 0 : valueString.length - decimalIndex - 1;

    return decimalPlaces <= precision;
  };

  return (
    <Modal
      title={title}
      open={visible}
      onOk={() => onOk(length)}
      onCancel={onCancel}
      className="model-layout"
      maskClosable={false}
    >
      {loading && (
        <div
          className="loader-container"
          style={{ display: loading ? "flex" : "none" }}
        >
          <Spin size="large" tip="Loading">
            <span></span>
          </Spin>
        </div>
      )}
      <div className="new-model-graph-plot-area-outer-layout">
        <div
          className="new-model-graph-plot-area"
          id="configure-origin-axis-measure-graph-plot-area"
        >
          {settingId === 3 && (
            <div className="length-input-outer-layout">
              <Text className="content-element-label">Length</Text>
              <InputNumber
                min={0.01}
                max={10000.0}
                step={1}
                precision={2}
                value={length}
                onBlur={(event) => {
                  const value = Number(event.target.value);
                  if (
                    event.target.value !== "" &&
                    value >= 0.01 &&
                    value <= 10000.0
                  ) {
                    setLength(value);
                  }
                }}
                onStep={(value) => {
                  const currentValue = parseFloat(length) - value;
                  let newValue = parseFloat(length) - currentValue;

                  if (newValue >= 0.01 && newValue <= 10000.0) {
                    setLength(value);
                  }
                }}
                onKeyDown={(event) => {
                  const allowedKeys = [
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    ".",
                    "Backspace",
                    "Delete",
                    "ArrowLeft",
                    "ArrowRight",
                    "Home",
                    "End",
                  ];

                  if (!allowedKeys.includes(event.key)) {
                    event.preventDefault();
                    return;
                  }

                  const inputValue = event.target.value;
                  const selectionStart = event.target.selectionStart;
                  const selectionEnd = event.target.selectionEnd;

                  if (event.key === "Backspace" || event.key === "Delete") {
                    if (selectionStart === selectionEnd) {
                      return;
                    }
                  }

                  if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
                    return;
                  }

                  if (
                    inputValue.startsWith("0") &&
                    event.key === "0" &&
                    selectionStart === 1
                  ) {
                    event.preventDefault();
                  }

                  const newValue =
                    inputValue.substring(0, selectionStart) +
                    event.key +
                    inputValue.substring(selectionEnd);

                  if (
                    !isValidNumberForDecimalValue(newValue, 0.01, 10000.0, 2)
                  ) {
                    event.preventDefault();
                  }
                }}
              />
              <Text className="content-element-units-label">m</Text>
            </div>
          )}

          {output === false
            ? null
            : removeCanvasElement(
                "configure-origin-axis-measure-graph-plot-area",
                fileName
              )}
        </div>
      </div>
    </Modal>
  );
}

export default ConfigureOriginAxisMeasure;
