import React from "react";
import { Button, Typography, Tooltip, Upload } from "antd";
import "./SettingNotExist.css";
const { Text } = Typography;

function SettingNotExist({ customRequest, beforeUpload, handleCreateButton }) {
  
  return (
    <>
      <div className="title-outer-layout">
        <Text className="title-text">All Files</Text>
      </div>

      <div className="content-outer-layout">
        <div className="content-layout">
          <div className="content">
            <div className="text-outer-layout">
              <Text className="heading-text">
                Click on Create to add new configuration or click on Import to
                upload local file
              </Text>
            </div>
            <div className="settingnotexist-buttons-outer-layout">
              <Tooltip title="Import configuration file from local system">
                <Upload
                  customRequest={customRequest}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                >
                  <Button className="import-button">Import</Button>
                </Upload>
              </Tooltip>

              <Tooltip title="Create a new configuration">
                <Button className="create-button" onClick={handleCreateButton}>
                  Create
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingNotExist;
