import React, { useEffect, useRef, useState } from "react";
import GlobalHeader from "../components/GlobalHeader";
import GraphPlotHeader from "../components/GraphPlotHeader";
import SideBar from "../components/SideBar";
import "./GraphPlotScreen.css";
import { Button, message, Spin, Tooltip, Modal } from "antd";
import graphScripts from "../python/GraphPlot.py";
import { AuthPostData } from "../services/Api";
import { endPoints } from "../services/EndPoints";
import { useDispatch, useSelector } from "react-redux";
import { setLidarInfo, selectLidarInfo } from "../redux/LidarSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import defaultJson from "../data/DefaultJSON";
import GraphPlotBackgroundImage from "../assets/graphPlotBackgroundImage.png";
import GenerateGraphModal from "../components/GenerateGraphModal";
import { removeCanvasElement } from "../utils/removeCanvasElement";

async function loadPyodide() {
  return new Promise(async (resolve) => {
    const pyodideInstance = await window.loadPyodide({
      indexURL: "https://cdn.jsdelivr.net/pyodide/v0.24.1/full/",
    });
    await pyodideInstance.loadPackage("micropip");
    await pyodideInstance.loadPackage("matplotlib");
    pyodideInstance.runPythonAsync(`
    import micropip
    await micropip.install('requests','idna')
    `);
    resolve(pyodideInstance);
  });
}

let loadPyodides = loadPyodide();

const runScript = async (code, last_json, image_data_url) => {
  return new Promise((resolve, reject) => {
    loadPyodides
      .then((loadedPyodide) => {
        let pyodide = loadedPyodide;
        pyodide.globals.set("last_json", last_json);
        pyodide.globals.set("image_data_url", image_data_url);
        resolve(pyodide.runPython(code));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

function GraphPlotScreen() {
  const [fileName, setFileName] = useState("GraphPlot");
  const [loading, setLoading] = useState(true);
  const [output, setOutput] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [count, setCount] = useState(0);
  const [image, setImage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lidarInfo = useSelector(selectLidarInfo);
  const location = useLocation();
  const fileCreatedDate = location.state?.createdDate;
  const [handleSensorAddedCalls, setHandleSensorAddedCalls] = useState(0);
  const [isGenerateModalVisible, setIsGenerateModalVisible] = useState(false);
  const [beamsPerCell, setBeamPerCell] = useState(null);
  const [pointsPerCell, setPointPerCell] = useState(null);
  const [viewPlotButtonDisable, setViewButtonDisable] = useState(0);
  const [screenDisable, setScreenDisable] = useState(false);
  const [viewPlotLidarInfo, setViewPlotLidarInfo] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  let fileId =
    searchParams.get("fileToken") !== "null"
      ? Number(atob(searchParams.get("fileToken")))
      : null;
  fileId = fileId ?? localStorage.getItem("fileId");

  const [generateButtonDisable, setGenerateButtonDisable] = useState(
    searchParams.get("isGenerateButtonEnable") === "true"
  );
  const timeOutRef = useRef(null);
  const [ViewPlotImageUrl, setViewPlotImageUrl] = useState(null);

  const toggleFlag = () => {
    setCount((prev) => prev + 1);
  };

  async function checkStatusOfGeneratedPlot() {
    const userProfile = JSON.parse(Cookies.get("profileData"));
    const generatedId = JSON.parse(localStorage.getItem("generatingList"));

    if (userProfile.userId) {
      try {
        let result = await AuthPostData(endPoints.isFilePlotGenerated, {
          userId: userProfile.userId,
          generatedFilesList: generatedId,
        });

        if (result.result.length !== 0) {
          Modal.success({
            title: "Graphs generated successfully",
            content: (
              <p>
                Plots for <strong>{result.result.join(", ")}</strong> are
                generated successfully
              </p>
            ),
            onOk: () => {},
          });
        }

        if (!result.generatingList.includes(fileId)) {
          setGenerateButtonDisable(result.isGenerated);
        }

        if (result.generatingList.length != generatedId.length) {
          const plotGeneratedIds = generatedId.filter(
            (value) => !result.generatingList.includes(value)
          );
          if (plotGeneratedIds.includes(fileId)) {
            setGenerateButtonDisable(result.isGenerated);
            setScreenDisable(false);
            setViewButtonDisable(2);
          }

          localStorage.setItem(
            "generatingList",
            JSON.stringify(result.generatingList)
          );
        }

        clearTimeout(timeOutRef.current);
        if (result?.generatingList?.length > 0) {
          timeOutRef.current = setTimeout(() => {
            checkStatusOfGeneratedPlot(result.generatingList);
          }, 30000);
        }
      } catch (error) {
        message.error(error);
      }
    }
  }

  useEffect(() => {
    checkStatusOfGeneratedPlot();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        (event || window.event).returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges]);

  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function (event) {
    window.history.forward();
  };

  const toDataURL = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          const splitURL = reader.result.split(",")[1];
          const dataURL = `data:image/png;base64,${splitURL}`;
          resolve(dataURL);
        };
        reader.onerror = reject;

        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    let divElements = document.querySelectorAll('div[id^="matplotlib"]');
    divElements.forEach(function (element) {
      element.parentNode.removeChild(element);
    });
    const fetchData = async () => {
      if (fileId) {
        let profileData = Cookies.get("profileData");
        profileData = JSON.parse(profileData);

        if (profileData) {
          try {
            setLoading(true);
            const result = await AuthPostData(endPoints.getSensorData, {
              email: profileData.email,
              userFileId: fileId,
            });
            dispatch(setLidarInfo(result.lidarInfo));
            setFileName(result.fileName);
            setViewButtonDisable(result.plotStatus);
            if (result.plotStatus === 1) {
              setGenerateButtonDisable(true);
            } else {
              setGenerateButtonDisable(result.isGenerated);
            }

            if (result.plotStatus === 1) {
              setScreenDisable(true);
            }

            if (
              result.lidarInfo &&
              result.lidarInfo.plotSettings["reference image"] &&
              result.lidarInfo.plotSettings["reference image"].filename
            ) {
              const imageUrl = await toDataURL(
                result.lidarInfo.plotSettings["reference image"].filename
              );
              setImage(imageUrl);
              let scriptText = await (await fetch(graphScripts)).text();
              await runScript(scriptText, result.lidarInfo, imageUrl);
            } else {
              let scriptText = await (await fetch(graphScripts)).text();
              await runScript(scriptText, result.lidarInfo, image);
            }
            setOutput(true);
            setLoading(false);
          } catch (errors) {
            setLoading(false);
            const error =
              errors.error ||
              "Unable to connect with the server, please try again after some time";
            message.error(error);
          }
        } else {
          message.error("Something went wrong please relogin");
          navigate("/login");
        }
      } else {
        setLoading(true);

        let scriptText = await (await fetch(graphScripts)).text();
        dispatch(setLidarInfo(defaultJson));

        await runScript(scriptText, defaultJson, image);
        setOutput(true);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    setOutput(false);
    let divElements = document.querySelectorAll('div[id^="matplotlib"]');
    divElements.forEach(function (element) {
      element.parentNode.removeChild(element);
    });

    const getData = async () => {
      let scriptText = await (await fetch(graphScripts)).text();
      await runScript(scriptText, lidarInfo, image);
      setOutput(true);
      setLoading(false);
    };

    if (count) {
      getData();
    }
  }, [count]);

  window.handleSensorAdded = (newLidarInfo1) => {
    setHandleSensorAddedCalls((prevCalls) => prevCalls + 1);
    if (handleSensorAddedCalls >= 1) {
      dispatch(setLidarInfo(newLidarInfo1));
      setUnsavedChanges(true);
    } else {
      dispatch(setLidarInfo(newLidarInfo1));
    }
  };

  const handleUnsavedChanges = (value) => {
    setUnsavedChanges(value);
    if (value === false) {
      setViewButtonDisable(0);
    }
  };

  const handleImageUpdate = (newImage) => {
    // Do something with the new image in the parent component
    setImage(newImage);
  };

  const checkGeneratedPlotStatus = async (generateFileID) => {
    let result = await AuthPostData(endPoints.isFilePlotGenerated, {
      userFileId: generateFileID,
    });

    timeOutRef.current = setTimeout(() => {
      if (result.isGenerated) {
        checkGeneratedPlotStatus(generateFileID);
      } else {
        setGenerateButtonDisable(result.isGenerated);
        setViewButtonDisable(result.plotStatus);
        setScreenDisable(false);
        Modal.success({
          title: "Graphs generated successfully",
          content: <p>{result.result}</p>,
          onOk: () => {},
        });
      }
    }, 30000);
  };

  const handleGenerateClick = async () => {
    setGenerateButtonDisable(true);
    if (!unsavedChanges) {
      if (lidarInfo.sensorsSettings.arrangement1.length > 0) {
        try {
          let result = await AuthPostData(endPoints.generate, {
            userFileId: fileId,
            lidarInfo: lidarInfo,
          });

          Modal.success({
            title: "Plot generation submitted",
            content: <p>{result.result}</p>,
            onOk: () => {},
          });
          // setGenerateButtonDisable(true);
          setViewButtonDisable(1);
          setScreenDisable(true);

          const existingGeneratingList =
            JSON.parse(localStorage.getItem("generatingList")) || [];

          if (existingGeneratingList.length === 0) {
            localStorage.setItem("generatingList", JSON.stringify([fileId]));
          } else {
            localStorage.setItem(
              "generatingList",
              JSON.stringify([...existingGeneratingList, fileId])
            );
          }
          await checkStatusOfGeneratedPlot();
        } catch (errors) {
          const error =
            errors.error ||
            "Unable to connect with the server, please try again after some time";
          message.error(error);
          setGenerateButtonDisable(false);
        } finally {
        }
      } else {
        Modal.info({
          content: (
            <div>
              <p>Atleast one sensor is required to generate</p>
            </div>
          ),
          centered: true,
          onOk() {
            setGenerateButtonDisable(false);
          },
        });
      }
    } else {
      Modal.info({
        content: (
          <div>
            <p>You must save the configuration before generating</p>
          </div>
        ),
        centered: true,
        onOk() {
          setGenerateButtonDisable(false);
        },
      });
    }
  };

  const handleViewPlotClick = async () => {
    let profileData = Cookies.get("profileData");
    profileData = JSON.parse(profileData);

    if (profileData) {
      try {
        setLoading(true);
        const result = await AuthPostData(endPoints.plotArray, {
          userFileId: fileId,
        });

        const output = await AuthPostData(endPoints.getSensorData, {
          email: profileData.email,
          userFileId: fileId,
        });

        if (
          output.lidarInfo &&
          output.lidarInfo.plotSettings["reference image"] &&
          output.lidarInfo.plotSettings["reference image"].filename
        ) {
          const imageUrl = await toDataURL(
            output.lidarInfo.plotSettings["reference image"].filename
          );

          setViewPlotImageUrl(imageUrl);
        }

        setViewPlotLidarInfo(output.lidarInfo);
        setBeamPerCell(JSON.parse(result.array1));
        setPointPerCell(JSON.parse(result.array2));
        setIsGenerateModalVisible(true);
      } catch (errors) {
        const error =
          errors.error ||
          "Unable to connect with the server, please try again after some time";
        message.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      message.error("Something went wrong please relogin");
      navigate("login");
    }
  };

  const handleGenerateModalOk = () => {
    setIsGenerateModalVisible(false);
  };

  const handleGenerateModalCancel = () => {
    setIsGenerateModalVisible(false);
    setHandleSensorAddedCalls(0);
    toggleFlag();
  };

  const handleAllFilesClick = () => {
    if (unsavedChanges) {
      Modal.confirm({
        title: "Unsaved Changes",
        content: "You have unsaved changes. Are you sure you want to proceed?",
        centered: true,
        onOk() {
          localStorage.removeItem("fileId");
          // clearTimeout(timeOutRef.current);
          if (timeOutRef.current) {
            clearTimeout(timeOutRef.current);
          }
          navigate("/homescreen");
        },
        onCancel() {},
      });
    } else {
      // No unsaved changes, proceed with navigation
      localStorage.removeItem("fileId");
      // clearTimeout(timeOutRef.current);
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
      navigate("/homescreen");
    }
  };

  const handleFileNameChange = (newFileName) => {
    setFileName(newFileName);
  };

  return (
    <div>
      <div
        className="loader-container"
        style={{ display: loading ? "flex" : "none" }}
      >
        <Spin size="large" tip="Loading">
          <span></span>
        </Spin>
      </div>

      <GlobalHeader
        checkUnsavedChanges={unsavedChanges}
        handleUnsavedChanges={handleUnsavedChanges}
      ></GlobalHeader>
      <GraphPlotHeader
        fileId={fileId}
        filename={fileName}
        handleUnsavedChanges={handleUnsavedChanges}
        onImageUpdate={handleImageUpdate}
        loadPyodide={loadPyodides}
        toggleFlag={toggleFlag}
        fileCreatedDate={fileCreatedDate}
        isAdjustButtonEnable={!image}
        adjustImage={image}
        screenDisable={screenDisable}
        handleAllFilesClick={handleAllFilesClick}
        updateUnsavedChanges={setHandleSensorAddedCalls}
        handleFileNameChange={handleFileNameChange}
      ></GraphPlotHeader>
      <div
        className={`graph-area-layout ${
          screenDisable ? "screen-disabled" : ""
        }`}
      >
        <div className="graph-plot-outer-layout">
          <div className="graph-plot-area-layout">
            <div className="plot-area" id="graph-plot-area">
              {output === false && loading ? (
                <img src={GraphPlotBackgroundImage} alt="Loading..." />
              ) : (
                removeCanvasElement("graph-plot-area", fileName + "_ringplot")
              )}
            </div>
          </div>
          <div className="graph-plot-side-bar-outer-layout">
            <SideBar toggleFlag={toggleFlag}></SideBar>
            <div className="generate-button-layout">
              <Tooltip
                title={
                  generateButtonDisable
                    ? "Maximum 3 graph generations is allowed concurrently, please wait until any of the generations is completed"
                    : "Generate plots per the configuration"
                }
              >
                <Button
                  className="generate-button"
                  onClick={handleGenerateClick}
                  disabled={generateButtonDisable}
                >
                  Generate
                </Button>
              </Tooltip>
              <Tooltip title="View plots per the configuration">
                <Button
                  className="generate-button"
                  onClick={handleViewPlotClick}
                  disabled={viewPlotButtonDisable !== 2}
                >
                  View Plots
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        {isGenerateModalVisible && (
          <GenerateGraphModal
            isVisible={isGenerateModalVisible}
            handleOk={handleGenerateModalOk}
            handleCancel={handleGenerateModalCancel}
            isOpacitySliderVisible={!image}
            loadPyodide={loadPyodides}
            image={ViewPlotImageUrl}
            pointsPerCell={pointsPerCell}
            beamsPerCell={beamsPerCell}
            lidarInfo={viewPlotLidarInfo}
            fileName={fileName}
          />
        )}
      </div>
    </div>
  );
}

export default GraphPlotScreen;
