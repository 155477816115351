import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Button,
  Table,
  Space,
  Tooltip,
  Modal,
  Upload,
  message,
  Spin,
  Select,
} from "antd";
import "./SettingExist.css";
import {
  DeleteOutlined,
  DownloadOutlined,
  CopyOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { AuthPostData, AuthGetData } from "../services/Api";
import { endPoints } from "../services/EndPoints";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import GenerateGraphModal from "./GenerateGraphModal";

const { Option } = Select;

const { Text } = Typography;

function SettingExist({
  userFiles,
  onDelete,
  customRequest,
  beforeUpload,
  onCopy,
  handleCreateButton,
  handleEdit,
  loadPyodides,
}) {
  const [loading, setLoading] = useState(true);
  const [datafiles, setDatafiles] = useState([]);
  const [isDeleteModelVisible, setDeleteModelVisible] = useState(false);
  const [generatedGraphModel, setGeneratedGraphModel] = useState(null);
  const [generatedGraphModelVisible, setGeneratedGraphModelVisible] =
    useState(false);
  const navigate = useNavigate();
  const tableContainerRef = useRef(null);
  const [pageSize, setPageSize] = useState(6);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const formattedData = await Promise.all(
          userFiles.map(async (files) => {
            const dateCreated = new Date(files.createdAt);
            const lastModified = new Date(files.updatedAt);

            const formatDate = (date) => {
              const day = date.getDate().toString().padStart(2, "0");
              const month = (date.getMonth() + 1).toString().padStart(2, "0");
              const year = date.getFullYear().toString();
              const hours = date.getHours().toString().padStart(2, "0");
              const minutes = date.getMinutes().toString().padStart(2, "0");
              const seconds = date.getSeconds().toString().padStart(2, "0");
              return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
            };

            return {
              key: files.id,
              name: files.file,
              "date created": formatDate(dateCreated),
              "last modified": formatDate(lastModified),
              lastModifiedTimestamp: lastModified.getTime(),
              status: files.plotStatus,
            };
          })
        );

        const sortedData = formattedData.sort(
          (a, b) => b.lastModifiedTimestamp - a.lastModifiedTimestamp
        );

        setDatafiles(sortedData);
      } catch (error) {
        message.error("Error formatting data");
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call the fetchData function when the component mounts
  }, [userFiles]);

  useEffect(() => {
    const handleResize = () => {
      const tableContainerHeight = tableContainerRef.current.clientHeight - 75;

      const newPageSize = Math.ceil(tableContainerHeight / 77);
      // // Update the state with the new pageSize
      setPageSize(newPageSize);
    };

    // Initial call to set the initial pageSize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); //

  const handleImagePath = async (newLidarInfo) => {
    const referenceImageFilename =
      newLidarInfo.plotSettings["reference image"].filename;

    const regex = /(sct\/.*)/;
    const match = referenceImageFilename.match(regex);

    if (match) {
      const result = match[1];
      return {
        ...newLidarInfo,
        plotSettings: {
          ...newLidarInfo.plotSettings,
          "reference image": {
            ...newLidarInfo.plotSettings["reference image"],
            filename: result,
          },
        },
      };
    }

    return newLidarInfo;
  };

  const askUserForDownloadChoice = () => {
    return new Promise((resolve) => {
      const modal = Modal.info({
        title:'Choose Download Option',
        content: (
          <div className="download-popup-content-outer-layout">
             <p>For importing in Desktop Application click on Desktop App Download. For importing in the web Application click on Web App Download</p>
            <div className="download-popup-download-button-outer-layout">
            <Button
              type="primary"
              onClick={() => {
                modal.destroy();
                resolve("desktopAppDownload");
              }}
              style={{ marginRight: 10 }}
            >
              Desktop App Download
            </Button>
            <Button
              type="primary"
              onClick={() => {
                modal.destroy();
                resolve("webAppDownload");
              }}
              style={{ marginRight: 10 }}
            >
              Web App Download
            </Button>
            </div>
          </div>
        ),
        icon: <DownloadOutlined style={{ color: 'green' }} />,
        okButtonProps: { style: { display: 'none' } },
        cancelButtonProps:{ style: { display: 'none' } },
        onCancel: () => {
          modal.destroy();
          resolve("cancel");
        },
        closable: true, // Display close button
        centered:true,
        width: '440px', // Set the width here

      });
    });
  };  

  const handleDownload = async (record) => {
    let profileData = Cookies.get("profileData");
    profileData = JSON.parse(profileData);

    if (profileData) {
      try {
        setLoading(true);
        const result = await AuthPostData(endPoints.getSensorData, {
          email: profileData.email,
          userFileId: record.key,
        });

        const jsonData = await handleImagePath(result.lidarInfo);

        // Check if image path exists in jsonData
        if (jsonData.plotSettings["reference image"].filename !== "") {
          const selectedOption = await askUserForDownloadChoice();
          if (selectedOption === "desktopAppDownload") {
            const getBucketUrl = await AuthGetData(endPoints.getS3Url);
            const imageUrl =
              getBucketUrl.data +
              jsonData.plotSettings["reference image"].filename;
            const response = await fetch(imageUrl);

            if (!response.ok) {
              throw new Error(
                `Failed to fetch image. Status: ${response.status}`
              );
            }

            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            const downloadLink = document.createElement("a");
            downloadLink.href = blobUrl;
            downloadLink.download = `${result.fileName}.png`; // Specify the desired filename
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }else if (selectedOption === "cancel") {
            return; // Do nothing or return from the function
          }
        
        }

        const jsonString = JSON.stringify(jsonData, null, 2);

        const blob = new Blob([jsonString], { type: "application/json" });
        const fileName = `${result.fileName}.json`;
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (errors) {
        const error =
          errors.error ||
          "Unable to connect with the server, please try again after some time";
        message.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      message.error("Something went wrong, please relogin");
      navigate("login");
    }
  };

  const showDeleteConfirm = (record) => {
    Modal.confirm({
      title: (
        <div className="custom-modal-content">
          Are you sure you want to delete file <br />
          <strong>{record.name}</strong>
        </div>
      ),
      icon: <DeleteOutlined style={{ color: "red" }} />,

      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      className: "custom-modal",
      onOk() {
        onDelete(record.key);
        setDeleteModelVisible(false);
      },
      onCancel() {
        setDeleteModelVisible(false);
      },
    });
  };

  const truncateString = (str, maxChars) => {
    if (str.length <= maxChars) {
      return str;
    }

    const truncated = str.substring(0, maxChars - 1) + "...";
    return truncated;
  };

  const toDataURL = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          const splitURL = reader.result.split(",")[1];
          const dataURL = `data:image/png;base64,${splitURL}`;
          resolve(dataURL);
        };
        reader.onerror = reject;

        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw error;
    }
  };

  const handleViewPlotClick = async (record) => {
    try {
      const result = await handleViewPlot(record);
      setGeneratedGraphModel(result);
      setGeneratedGraphModelVisible(true);
    } catch (error) {
      message.error(error);
    }
  };

  const handleViewPlot = async (record) => {
    let profileData = Cookies.get("profileData");
    profileData = JSON.parse(profileData);

    if (profileData) {
      try {
        setLoading(true);
        const result = await AuthPostData(endPoints.plotArray, {
          userFileId: record.key,
        });

        const output = await AuthPostData(endPoints.getSensorData, {
          email: profileData.email,
          userFileId: record.key,
        });

        if (
          output.lidarInfo &&
          output.lidarInfo.plotSettings["reference image"] &&
          output.lidarInfo.plotSettings["reference image"].filename
        ) {
          const imageUrl = await toDataURL(
            output.lidarInfo.plotSettings["reference image"].filename
          );

          return {
            isVisible: true,
            isOpacitySliderVisible: !imageUrl,
            image: imageUrl,
            pointsPerCell: result.array2,
            beamsPerCell: result.array1,
            lidarInfo: output.lidarInfo,
            fileName: output.fileName,
          };
        }

        return {
          isVisible: true,
          isOpacitySliderVisible: true,
          image: "",
          pointsPerCell: result.array2,
          beamsPerCell: result.array1,
          lidarInfo: output.lidarInfo,
          fileName: output.fileName,
        };
      } catch (errors) {
        const error =
          errors.error ||
          "Unable to connect with the server, please try again after some time";
        message.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      message.error("Something went wrong please relogin");
      navigate("login");
    }
  };

  const handleGenerateModalCancel = () => {
    setGeneratedGraphModelVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "name-column",
      width: "55vw",
      render: (text, record) => (
        <Tooltip title={text}>
          <span
            onClick={() => handleEdit(record)}
            style={{ cursor: "pointer" }}
          >
            {truncateString(text, 80)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "date created",
      key: "date created",
      className: "date-created-column",
      width: "15vw",
    },
    {
      title: "Last Modified",
      dataIndex: "last modified",
      key: "last modified",
      className: "last-modified-column",
      width: "15vw",
    },
    {
      title: "Actions",
      key: "actions",
      width: "15vw",
      className: "action-column",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Copy the saved configuration into online account">
            <Button
              type="link"
              icon={<CopyOutlined />}
              onClick={() => onCopy(record)}
              style={{ color: "blue" }}
            />
          </Tooltip>

          <Tooltip title="Download the saved configuration into local system">
            <Button
              type="link"
              icon={<DownloadOutlined />}
              onClick={() => handleDownload(record)}
              style={{ color: "green" }}
            />
          </Tooltip>

          {record.status === 2 ? (
            <Tooltip title="View plots per the configuration ">
              <Button
                type="link"
                icon={<BarChartOutlined />}
                onClick={() => {
                  handleViewPlotClick(record);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="View plots per the configuration">
              <Button type="link" icon={<BarChartOutlined />} disabled />
            </Tooltip>
          )}

          <Tooltip title="Delete the configuration from the online account">
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => {
                if (!isDeleteModelVisible && record.status !== 1) {
                  showDeleteConfirm(record);
                  setDeleteModelVisible(true);
                }
              }}
              danger
              disabled={record.status === 1}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div
        className="loader-container"
        style={{ display: loading ? "flex" : "none" }}
      >
        <Spin size="large" tip="Loading">
          <span></span>
        </Spin>
      </div>

      <div className="title-outer-layout">
        <Text className="title-text">All Files</Text>
        <div className="title-button-outer-layout">
          <Tooltip title="Import configuration file from local system">
            <Upload
              customRequest={customRequest}
              showUploadList={false}
              beforeUpload={beforeUpload}
            >
              <Button className="import-button">Import</Button>
            </Upload>
          </Tooltip>
          <Tooltip title="Create a new configuration">
            <Button className="create-button" onClick={handleCreateButton}>
              Create
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="outer-content-layout">
        <div className="outer-table-layout" ref={tableContainerRef}>
          <Table
            dataSource={datafiles}
            columns={columns}
            pagination={{
              pageSize: pageSize,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>

      {generatedGraphModelVisible && (
        <GenerateGraphModal
          handleCancel={handleGenerateModalCancel}
          isVisible={generatedGraphModel.isVisible}
          isOpacitySliderVisible={generatedGraphModel.isOpacitySliderVisible}
          image={generatedGraphModel.image}
          pointsPerCell={JSON.parse(generatedGraphModel.pointsPerCell)}
          beamsPerCell={JSON.parse(generatedGraphModel.beamsPerCell)}
          lidarInfo={generatedGraphModel.lidarInfo}
          loadPyodides={loadPyodides}
          fileName={generatedGraphModel.fileName}
        />
      )}
    </>
  );
}

export default SettingExist;
