const Arrangement1ValidateJSON = {
  sensorsSettings: {
    arrangement1: [
      {
        sensorId: "unnamed",
        type: "mq8",
        range: 85,
        x: -39.74772272794662,
        y: 40.75249014481756,
        z: 3.25,
        yaw_angle: 90,
        pitch_angle: 0,
        roll_angle: 0,
        min_angle: -180,
        max_angle: 180,
        frame: "world",
        spin_rate: 10,
        use_intrinsic: true,
      },
    ],
    arrangement2: [],
  },
  simulationSettings: {
    ground_offset: 0.25,
    tracked_object_height: 1.75,
    obstacles: [
      {
        center: [0, 0, 0],
        length: 10,
        width: 10,
        height: 2,
        rotation: 0,
      },
    ],
    total_area: {
      min_x: -40,
      max_x: 40,
      min_y: -40,
      max_y: 40,
    },
    sample_size: {
      length: 1,
      width: 1,
    },
  },
  plotSettings: {
    "reference image": {
      filename: "",
      origin: {
        x: 0.0,
        y: 0.0,
      },
      rotation: 0.0,
      scale: 1.0,
      alpha: 0.5,
    },
    "image prefix": "./test/",
    arrangement1: {
      ring: {
        enabled: "true",
        sensorsFilledSetting: {
          sensor1: {
            color: "magenta",
            alpha: 0.2,
          },
        },
        "save png": "false",
      },
      beam_coverage: {
        enabled: "true",
        "discrete colors": "false",
        "save png": "false",
        range: {
          enabled: "false",
          min: 0,
          max: 3,
        },
      },
      beam_histogram: {
        enabled: "true",
        "save png": "false",
      },
      point_coverage: {
        enabled: "true",
        "discrete colors": "false",
        "save png": "false",
        range: {
          enabled: "false",
          min: 0,
          max: 100,
        },
      },
      point_histogram: {
        enabled: "true",
        "save png": "false",
      },
    },
    arrangement2: {
      ring: {
        enabled: "true",
        sensorsFilledSetting: {
          sensor1: {
            color: "magenta",
            alpha: 0.2,
          },
        },
        "save png": "false",
      },
      beam_coverage: {
        enabled: "true",
        "discrete colors": "false",
        "save png": "false",
      },
      beam_histogram: {
        enabled: "true",
        "save png": "false",
      },
      point_coverage: {
        enabled: "true",
        "discrete colors": "false",
        "save png": "false",
      },
      point_histogram: {
        enabled: "true",
        "save png": "false",
      },
    },
    comparison: {
      "beam_coverage comparison": {
        enabled: "false",
        "discrete colors": "false",
        "save png": "false",
      },
      "point_coverage comparison": {
        enabled: "false",
        "discrete colors": "false",
        "save png": "false",
      },
    },
  },
};

export default Arrangement1ValidateJSON;
