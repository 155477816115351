// SensorComponent.js
import React from "react";
import { Button, Typography, InputNumber, Select, Input, Tooltip } from "antd";
import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import "./SensorObstacleComponent.css";
const { Text } = Typography;
const { Option } = Select;

const SensorComponent = ({
  sensorData,
  isActive,
  onClick,
  onDelete,
  onInputChange,
  toggleFlag,
}) => {
  const toggleSensorElement = (status) => {
    onClick(status); // Toggle the active sensor when clicked
  };

  const isValidNumber = (value, min, max, precision) => {
    if (value === "") {
      return true; // Allow an empty value
    }

    const numericValue = Number(value);

    // Check if the numeric value is within the specified range
    if (numericValue < min || numericValue > max) {
      return false;
    }

    // Check if the numeric value has the correct precision
    const valueString = String(value);
    const decimalIndex = valueString.indexOf(".");
    const decimalPlaces =
      decimalIndex === -1 ? 0 : valueString.length - decimalIndex - 1;

    return decimalPlaces <= precision;
  };

  const truncateString = (str, maxChars) => {
    if (str.length <= maxChars) {
      return str;
    }

    const truncated = str.substring(0, maxChars - 1) + "...";
    return truncated;
  };

  const handleSensorPositionInputOnKeyDown = (event) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      "-",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    const inputValue = event.target.value;
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    if (inputValue.startsWith("0") && event.key === "0" && selectionStart === 1) {
      event.preventDefault();
    }

    // Handle Backspace and Delete keys
    if (event.key === "Backspace" || event.key === "Delete") {
      // Allow the default behavior for Backspace and Delete keys if no selection
      if (selectionStart === selectionEnd) {
        return;
      }
    }

    // If the user is moving the cursor (ArrowLeft or ArrowRight), allow it
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      return;
    }

    if (event.key === "." && inputValue.includes(".")) {
      event.preventDefault();
      return;
    }

    // Check if the pressed key is a minus sign
    if (event.key === "-") {
      // If the cursor is not at the beginning or there's already a minus sign, prevent entry
      if (selectionStart !== 0 || inputValue.includes("-")) {
        event.preventDefault();
        return;
      }
    }

    if (inputValue.includes("-") && selectionStart === 0) {
      event.preventDefault();
      return;
    }

    if (event.key === "0" && selectionStart > 0) {
      // If there's already a zero at the current cursor position, prevent entry
      if (
        inputValue.charAt(selectionStart - 1) === "-" &&
        inputValue.charAt(selectionStart - 2) !== "."
      ) {
        event.preventDefault();
        return;
      }
    }

    // Replace the selected part of the input value with the pressed key
    const newValue =
      inputValue.substring(0, selectionStart) +
      event.key +
      inputValue.substring(selectionEnd);

    // Check if the new value is a valid number within the allowed range and precision
    if (!isValidNumber(newValue, -10000.0, 10000.0, 2)) {
      event.preventDefault();
    }

    // Update the state based on the field name
    // onInputChange(fieldName, newValue);
    // toggleFlag();
  };

  const handleSensorFiledInDegreesOnKeyDown = (
    event,
    minValue,
    maxValue,
    precision
  ) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      "-",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    const inputValue = event.target.value;
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    if (inputValue.startsWith("0") && event.key === "0" && selectionStart === 1) {
      event.preventDefault();
    }

    // Handle Backspace and Delete keys
    if (event.key === "Backspace" || event.key === "Delete") {
      // Allow the default behavior for Backspace and Delete keys if no selection
      if (selectionStart === selectionEnd) {
        return;
      }
    }

    // If the user is moving the cursor (ArrowLeft or ArrowRight), allow it
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      return;
    }

    if (event.key === "." && inputValue.includes(".")) {
      event.preventDefault();
      return;
    }

    // Check if the pressed key is a minus sign
    if (event.key === "-") {
      // If the cursor is not at the beginning or there's already a minus sign, prevent entry
      if (selectionStart !== 0 || inputValue.includes("-")) {
        event.preventDefault();
        return;
      }
    }

    if (inputValue.includes("-") && selectionStart === 0) {
      event.preventDefault();
      return;
    }

    if (event.key === "0" && selectionStart > 0) {
      // If there's already a zero at the current cursor position, prevent entry
      if (
        inputValue.charAt(selectionStart - 1) === "-" &&
        inputValue.charAt(selectionStart - 2) !== "."
      ) {
        event.preventDefault();
        return;
      }
    }

    // Replace the selected part of the input value with the pressed key
    const newValue =
      inputValue.substring(0, selectionStart) +
      event.key +
      inputValue.substring(selectionEnd);

    // Check if the new value is a valid number within the allowed range and precision
    if (!isValidNumber(newValue, minValue, maxValue, precision)) {
      event.preventDefault();
      return;
    }

    // // Call the specific onInputChange function with the updated value
    // onInputChange(newValue);
  };

  const handleSensorSpinRateAndRangeOnKeyDown = (
    event,
    minValue,
    maxValue,
    precision
  ) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    const inputValue = event.target.value;
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    if (inputValue.startsWith("0") && event.key === "0" && selectionStart === 1) {
      event.preventDefault();
    }

    // Handle Backspace and Delete keys
    if (event.key === "Backspace" || event.key === "Delete") {
      // Allow the default behavior for Backspace and Delete keys if no selection
      if (selectionStart === selectionEnd) {
        return;
      }
    }

    // If the user is moving the cursor (ArrowLeft or ArrowRight), allow it
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      return;
    }

    if (event.key === "." && inputValue.includes(".")) {
      event.preventDefault();
      return;
    }

    // Check if the pressed key is a minus sign
    if (event.key === "-") {
      // If the cursor is not at the beginning or there's already a minus sign, prevent entry
      if (selectionStart !== 0 || inputValue.includes("-")) {
        event.preventDefault();
        return;
      }
    }

    if (inputValue.includes("-") && selectionStart === 0) {
      event.preventDefault();
      return;
    }

    if (event.key === "0" && selectionStart > 0) {
      // If there's already a zero at the current cursor position, prevent entry
      if (
        inputValue.charAt(selectionStart - 1) === "-" &&
        inputValue.charAt(selectionStart - 2) !== "."
      ) {
        event.preventDefault();
        return;
      }
    }

    // Replace the selected part of the input value with the pressed key
    const newValue =
      inputValue.substring(0, selectionStart) +
      event.key +
      inputValue.substring(selectionEnd);

    // Check if the new value is a valid number within the allowed range and precision
    if (!isValidNumber(newValue, minValue, maxValue, precision)) {
      event.preventDefault();
      return;
    }

    // Call the specific onInputChange function with the updated value
    // onInputChange(newValue);
  };

  return (
    <>
      {!isActive ? (
        <div className="sensor-outer-layout">
          <div className="sensor-layout">
            <Text className="sensor-field-name">
              <Tooltip
                title={sensorData.sensorId}
                placement="top"
                arrowPlacement="left"
              >
                {truncateString(sensorData.sensorId, 40)}
              </Tooltip>
            </Text>

            <Button
              type="link"
              className="buttons-outer-layout"
              icon={<DeleteOutlined />}
              onClick={() => onDelete()}
              danger
            ></Button>
            <Button
              type="default"
              className="buttons-outer-layout"
              icon={<DownOutlined />}
              onClick={() => toggleSensorElement(true)}
            ></Button>
          </div>
        </div>
      ) : (
        <div className="sensor-obstacle-fields-outer-layout">
          <div className="sensor-obstacle-fields-column-layout">
            <div className="sensor-field-name-layout">
              <Input
                className="input-fields-value"
                maxLength={150}
                minLength={1}
                value={sensorData.sensorId}
                onBlur={toggleFlag}
                onChange={(event) => onInputChange("sensorId", event.target.value)}
              />

              <Button
                type="link"
                className="buttons-outer-layout"
                icon={<DeleteOutlined />}
                onClick={() => onDelete()}
                danger
              ></Button>
              <Button
                type="default"
                className="buttons-outer-layout"
                icon={<UpOutlined />}
                onClick={() => toggleSensorElement(false)}
              ></Button>
            </div>

            <div className="fields-data-outer-layout">
              <Text className="sensor-field-label-light">Type</Text>
              <Select
                className="input-fields-value"
                value={sensorData.type}
                onChange={(value) => onInputChange("type", value)}
                onBlur={() => toggleFlag()}
              >
                <Option value="mq8">mq8</Option>
                <Option value="m1">m1</Option>
                <Option value="m8">m8</Option>
{/*                <Option value="hd">hd</Option>
                <Option value="dome">dome</Option>*/}
              </Select>
            </div>
          </div>
          <div className="sensor-obstacle-fields-column-layout">
            <Text className="sensor-field-label-name">Position</Text>
            <div className="fields-row-outer-layout">
              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">X</Text>
                <InputNumber
                  className="input-fields-value"
                  min={-10000.0}
                  max={10000.0}
                  step={1} // Set the step to control decimal places
                  precision={2}
                  value={sensorData.x}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -10000.0 &&
                      value <= 10000.0
                    ) {
                      onInputChange("x", value);
                    }

                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue = parseFloat(sensorData.x) - value;
                    const newValue = parseFloat(sensorData.x) - currentValue;

                    if (newValue >= -10000.0 && newValue <= 10000.0) {
                      onInputChange("x", newValue);
                    }
                  }}
                  onKeyDown={(event) => handleSensorPositionInputOnKeyDown(event)}
                />
                <Text className="sensor-field-label-value">m</Text>
              </div>

              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">Y</Text>

                <InputNumber
                  className="input-fields-value"
                  min={-10000.0}
                  max={10000.0}
                  step={1} // Set the step to control decimal places
                  precision={2}
                  value={sensorData.y}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -10000.0 &&
                      value <= 10000.0
                    ) {
                      onInputChange("y", value);
                    }

                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue = parseFloat(sensorData.y) - value;
                    const newValue = parseFloat(sensorData.y) - currentValue;

                    if (newValue >= -10000.0 && newValue <= 10000.0) {
                      onInputChange("y", newValue);
                    }
                  }}
                  onKeyDown={(event) => handleSensorPositionInputOnKeyDown(event)}
                />
                <Text className="value-text">m</Text>
              </div>
              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">Z</Text>
                <InputNumber
                  className="input-fields-value"
                  min={-10000.0}
                  max={10000.0}
                  step={1} // Set the step to control decimal places
                  precision={2}
                  value={sensorData.z}
                  onBlur={(event) => {
                    const value = Number(event.target.value);

                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -10000.0 &&
                      value <= 10000.0
                    ) {
                      onInputChange("z", value);
                    }

                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue = parseFloat(sensorData.z) - value;
                    const newValue = parseFloat(sensorData.z) - currentValue;

                    if (newValue >= -10000.0 && newValue <= 10000.0) {
                      onInputChange("z", newValue);
                    }
                  }}
                  onKeyDown={(event) => handleSensorPositionInputOnKeyDown(event)}
                />
                <Text className="sensor-field-label-value">m</Text>
              </div>
            </div>
          </div>

          <div className="sensor-obstacle-fields-column-layout">
            <Text className="sensor-field-label-name">Orientation</Text>
            <div className="fields-row-outer-layout">
              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">Yaw</Text>
                <InputNumber
                  className="input-fields-value"
                  min={-180.0}
                  max={180.0}
                  step={1}
                  precision={1}
                  value={sensorData.yaw_angle}
                  onBlur={(event) => {
                    const value = Number(event.target.value);
                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -180.0 &&
                      value <= 180.0
                    ) {
                      onInputChange("yaw_angle", value);
                    }

                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue =
                      parseFloat(sensorData.yaw_angle) - value;

                    const newValue =
                      parseFloat(sensorData.yaw_angle) - currentValue;
                    if (newValue >= -180.0 && newValue <= 180.0) {
                      onInputChange("yaw_angle", newValue);
                    }
                  }}
                  onKeyDown={(event) =>
                    handleSensorFiledInDegreesOnKeyDown(event, -180.0, 180.0, 1)
                  }
                />

                <Text className="sensor-field-label-value">°</Text>
              </div>

              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">Pitch</Text>
                <InputNumber
                  className="input-fields-value"
                  min={-180.0}
                  max={180.0}
                  step={1}
                  precision={1}
                  value={sensorData.pitch_angle}
                  onBlur={(event) => {
                    const value = Number(event.target.value);
                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -180.0 &&
                      value <= 180.0
                    ) {
                      onInputChange("pitch_angle", value);
                    }

                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue =
                      parseFloat(sensorData.pitch_angle) - value;
                    const newValue =
                      parseFloat(sensorData.pitch_angle) - currentValue;
                    if (newValue >= -180.0 && newValue <= 180.0) {
                      onInputChange("pitch_angle", newValue);
                    }
                  }}
                  onKeyDown={(event) =>
                    handleSensorFiledInDegreesOnKeyDown(event, -180.0, 180.0, 1)
                  }
                />
                <Text className="sensor-field-label-value">°</Text>
              </div>
              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">Roll</Text>
                <InputNumber
                  className="input-fields-value"
                  min={-180.0}
                  max={180.0}
                  step={1}
                  precision={1}
                  value={sensorData.roll_angle}
                  onBlur={(event) => {
                    const value = Number(event.target.value);
                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -180.0 &&
                      value <= 180.0
                    ) {
                      onInputChange("roll_angle", value);
                    }

                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue =
                      parseFloat(sensorData.roll_angle) - value;
                    const newValue =
                      parseFloat(sensorData.roll_angle) - currentValue;
                    if (newValue >= -180.0 && newValue <= 180.0) {
                      onInputChange("roll_angle", newValue);
                    }
                  }}
                  onKeyDown={(event) =>
                    handleSensorFiledInDegreesOnKeyDown(event, -180.0, 180.0, 1)
                  }
                />
                <Text className="sensor-field-label-value">°</Text>
              </div>
            </div>
          </div>
          <div className="sensor-obstacle-fields-column-layout">
            <Text className="sensor-field-label-name">
              Coverage Specifications
            </Text>
            <div className="fields-row-outer-layout">
              <div className="fields-data-outer-layout">
                <Text
                  className="sensor-field-label-light"
                  style={{ lineHeight: "20px", textAlign: "left" }}
                >
                  Min Angle
                </Text>
                <InputNumber
                  className="input-fields-value"
                  min={-180.0}
                  max={180.0}
                  step={1}
                  precision={1}
                  value={sensorData.min_angle}
                  onBlur={(event) => {
                    const value = Number(event.target.value);
                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -180.0 &&
                      value <= 180.0
                    ) {
                      onInputChange("min_angle", value);
                    }

                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue =
                      parseFloat(sensorData.min_angle) - value;
                    const newValue =
                      parseFloat(sensorData.min_angle) - currentValue;
                    if (newValue >= -180.0 && newValue <= 180.0) {
                      onInputChange("min_angle", newValue);
                    }
                  }}
                  onKeyDown={(event) =>
                    handleSensorFiledInDegreesOnKeyDown(event, -180.0, 180.0, 1)
                  }
                />
                <Text className="sensor-field-label-value">°</Text>
              </div>

              <div className="fields-data-outer-layout">
                <Text
                  className="sensor-field-label-light"
                  style={{ lineHeight: "20px", textAlign: "left" }}
                >
                  Max Angle
                </Text>
                <InputNumber
                  className="input-fields-value"
                  min={-180.0}
                  max={180.0}
                  step={1}
                  precision={1}
                  value={sensorData.max_angle}
                  onBlur={(event) => {
                    const value = Number(event.target.value);
                    if (
                      event.target.value !== "" &&
                      !isNaN(value) &&
                      value >= -180.0 &&
                      value <= 180.0
                    ) {
                      onInputChange("max_angle", value);
                    }

                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue =
                      parseFloat(sensorData.max_angle) - value;
                    const newValue =
                      parseFloat(sensorData.max_angle) - currentValue;
                    if (newValue >= -180 && newValue <= 180) {
                      onInputChange("max_angle", newValue);
                    }
                  }}
                  onKeyDown={(event) =>
                    handleSensorFiledInDegreesOnKeyDown(event, -180.0, 180.0, 1)
                  }
                />
                <Text className="sensor-field-label-value">°</Text>
              </div>
              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">Range</Text>
                <InputNumber
                  className="input-fields-value"
                  min={0.01}
                  max={250.0}
                  step={1} // Set the step to control decimal places
                  precision={2} // Set the precision to restrict decimal places
                  value={sensorData.range}
                  onBlur={(event) => {
                    const value = Number(event.target.value);
                    if (
                      event.target.value !== "" &&
                      value >= 0.01 &&
                      value <= 250.0
                    ) {
                      onInputChange("range", value);
                    }

                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue = parseFloat(sensorData.range) - value;
                    const newValue =
                      parseFloat(sensorData.range) - currentValue;
                    if (newValue >= 0.01 && newValue <= 250.0) {
                      onInputChange("range", newValue);
                    }
                  }}
                  onKeyDown={(event) =>
                    handleSensorSpinRateAndRangeOnKeyDown(event, 0.01, 250.0, 2)
                  }
                />
                <Text className="sensor-field-label-value">m</Text>
              </div>
            </div>
          </div>

          <div className="sensor-obstacle-fields-column-layout">
            <Text className="sensor-field-label-name">Motion and Frame</Text>
            <div className="fields-row-outer-layout">
              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">Frame</Text>
                <Text className="sensor-field-label-value">
                  {sensorData.frame}
                </Text>
              </div>

              <div className="fields-data-outer-layout">
                <Text className="sensor-field-label-light">Spin Rate</Text>
                <InputNumber
                  className="input-fields-value"
                  min={1.0}
                  max={100.0}
                  step={1}
                  precision={1}
                  value={sensorData.spin_rate}
                  onBlur={(event) => {
                    const value = Number(event.target.value);
                    if (
                      event.target.value !== "" &&
                      value >= 1.0 &&
                      value <= 100.0
                    ) {
                      onInputChange("spin_rate", value);
                    }

                    toggleFlag();
                  }}
                  onStep={(value) => {
                    const currentValue =
                      parseFloat(sensorData.spin_rate) - value;
                    const newValue =
                      parseFloat(sensorData.spin_rate) - currentValue;
                    if (newValue >= 1.0 && newValue <= 100.0) {
                      onInputChange("spin_rate", newValue);
                    }
                  }}
                  onKeyDown={(event) =>
                    handleSensorSpinRateAndRangeOnKeyDown(event, 1.0, 100.0, 1)
                  }
                />
                <Text className="sensor-field-label-value">Hz</Text>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SensorComponent;
