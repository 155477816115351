import React, { useEffect, useState } from "react";
import "./HomeScreen.css";
import GlobalHeader from "../components/GlobalHeader";
import { Spin, message, Select, Modal } from "antd";
import SettingExist from "../components/SettingExist";
import SettingNotExist from "../components/SettingNotExist";
import { AuthPostData, AuthDeleteData, AuthGetData } from "../services/Api";
import { endPoints } from "../services/EndPoints";
import Cookies from "js-cookie";
import Arrangement1ValidateJSON from "../data/Arrangement1ValidateJSON";
import Arrangement2ValidateJSON from "../data/Arrangement2ValidateJSON";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const { Option } = Select;

function HomeScreen() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generatedButtonEnable, setGenerateButtonEnable] = useState(null);

  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  const fetchData = async () => {
    if (Cookies.get("profileData")) {
      setLoading(true);
      try {
        let profileData = Cookies.get("profileData");
        profileData = JSON.parse(profileData);

        const result = await AuthPostData(endPoints.getUserFiles, {
          email: profileData.email,
        });
        setGenerateButtonEnable(result.isGenerated);
        setData(result.data);

      } catch (errors) {
        // Handle parsing error
        const error =
          errors.error ||
          "Unable to connect with the server, please try again after some time";
        message.error(error);
      } finally {
        setLoading(false);
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const CheckGeneratePlotStatus = async (generatingPlotFileIds) => {
    const userProfile = JSON.parse(Cookies.get("profileData"));

    if (userProfile.userId) {
    try {
      let result = await AuthPostData(endPoints.isFilePlotGenerated, {
        userId:userProfile.userId,
        generatedFilesList:generatingPlotFileIds
      });

      localStorage.setItem('generatingList',JSON.stringify(result.generatingList));

      if(result.result.length !== 0) {
        Modal.success({
          title: "Graphs generated successfully",
          content: <p>Plots for <strong>{result.result.join(", ")}</strong> are generated successfully</p>,
          onOk: () => {},
        });
        fetchData();
      }
      
      if (result?.generatingList?.length > 0) {
        timeoutRef.current = setTimeout(() => {
          CheckGeneratePlotStatus(result.generatingList);
        }, 30000);
      } 
    } catch (error) {
      message.error(error);
    }
   }
  };

  useEffect(() => {
      const elementsWithPlotStatus1 = data?.filter((item) => item.plotStatus === 1);
      const generatingPlotFileIds = elementsWithPlotStatus1?.map((item) => item.id);
      localStorage.setItem('generatingList',JSON.stringify(generatingPlotFileIds))
    if (generatingPlotFileIds && generatingPlotFileIds.length >0) {
      CheckGeneratePlotStatus(generatingPlotFileIds);
    }
  }, [data]);

  const handleCreateButton = () => {

    const queryString = `?fileToken=${null}&isGenerateButtonEnable=${generatedButtonEnable}`;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    navigate(`/graphplotScreen${queryString}`);
    localStorage.removeItem("fileId");
  };

  const compareJsonStructure = (obj1, obj2) => {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return true;
    }

    const keys1 = Object.keys(obj1).sort();
    const keys2 = Object.keys(obj2).sort();

    if (
      keys1.length !== keys2.length ||
      !keys1.every((key, index) => key === keys2[index])
    ) {
      return false;
    }

    for (const key of keys1) {
      if (Array.isArray(obj1[key]) || Array.isArray(obj2[key])) {
        if (obj1[key].length === 0 || obj2[key].length === 0) {
          continue;
        }

        let matches = obj2[key].every((obj) =>
          compareJsonStructure(obj1[key][0], obj)
        );
        if (!matches) {
          return false;
        }
      } else if (!compareJsonStructure(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const validateRecursive = (data, parentKey = null) => {
    if (Array.isArray(data)) {
      return data.every((item) => validateRecursive(item, parentKey));
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        const combinedKey = parentKey ? `${parentKey}.${key}` : key;
        switch (combinedKey) {
          case "arrangement1.x":
          case "arrangement1.y":
          case "arrangement1.z":
          case "arrangement2.x":
          case "arrangement2.y":
          case "arrangement2.z":
          case "obstacles.length":
          case "obstacles.width":
          case "total_area.min_x":
          case "total_area.min_y":
          case "total_area.max_x":
          case "total_area.max_y":
            if (
              typeof value !== "number" ||
              value < -10000.0 ||
              value > 10000.0
            ) {
              return false;
            }
            break;
          case "arrangement1.sensorId":
          case "arrangement2.sensorId":
            if (value.length === 0 || value.length > 150) {
              return false;
            }
            break;

          case "arrangement1.yaw_angle":
          case "arrangement1.pitch_angle":
          case "arrangement1.roll_angle":
          case "arrangement1.min_angle":
          case "arrangement1.max_angle":
            if (typeof value !== "number" || value < -180 || value > 180) {
              return false;
            }
            break;

          case "arrangement1.range":
            if (typeof value !== "number" || value < 0.01 || value > 250.0) {
              return false;
            }
            break;

          case "arrangement1.spin_rate":
            if (typeof value !== "number" || value < 1.0 || value > 100.0) {
              return false;
            }
            break;
          case "obstacles.height":
            if (typeof value !== "number" || value < 0.0 || value > 10000) {
              return false;
            }
            break;

          case "obstacles.rotation":
            if (typeof value !== "number" || value < -360 || value > 360) {
              return false;
            }
            break;
          case "origin.alpha":
            if (typeof value !== "number" || value < 0.0 || value > 100.0) {
              return false;
            }
            break;
          case "range.min":
          case "range.max":
            if (typeof value !== "number" || value < 0 || value > 1000) {
              return false;
            }
            break;

          case "origin.x":
          case "origin.y":
            if (typeof value !== "number" || value < -2000 || value > 2000) {
              return false;
            }
            break;

          case "reference image.rotation":
            if (typeof value !== "number" || value < 0.0 || value > 360) {
              return false;
            }
            break;

          case "sample_size.length":
          case "sample_size.width":
            if (typeof value !== "number" || value < 0.01 || value > 100) {
              return false;
            }
            break;

          case "simulationSettings.ground_offset":
          case "simulationSettings.tracked_object_height":
            if (typeof value !== "number" || value < 0.0 || value > 100) {
              return false;
            }
            break;

          case "reference image.scale":
            if (typeof value !== "number" || value < 0.01 || value > 10000) {
              return false;
            }
            break;

          case "reference image.alpha":
            if (typeof value !== "number" || value < 0 || value > 1) {
              return false;
            }
            break;

          case "obstacles.center":
            if (!Array.isArray(value)) {
              return false; // If not an array, it's invalid
            }

            if (value.length !== 3) {
              return false;
            }

            for (const centerValue of value) {
              if (
                typeof centerValue !== "number" ||
                centerValue < -10000.0 ||
                centerValue > 10000.0
              ) {
                return false; // If any value is out of range, return false
              }
            }
            break;

          case "arrangement1.type":
          case "arrangement2.type":
            if (
              typeof value !== "string" ||
              (value !== "mq8" && value !== "m1" && value !== "m8" && value !== "hd" && value !== "dome")
            ) {
              return false;
            }
            break;

          default:
            // Check if the value is an object and validate recursively
            if (typeof value === "object" && value !== null) {
              if (!validateRecursive(value, key)) {
                return false;
              }
            }
            // If it's not an object or a number, perform numeric range validation
            else if (typeof value === "number") {
              if (!validateRecursive(value, key)) {
                return false;
              }
              // Perform generic numeric range validation if needed
            }
            // Handle unknown keys or keys without validation
            break;
        }
      }
    }

    return true;
  };

  const checkTheImagePath = (uploadedJson) => {
    const imageUrl = uploadedJson.plotSettings["reference image"].filename;

    if (imageUrl.trim() !== "" && !imageUrl.startsWith("sct")) {
      return true;
    }
    return false;
  };

  const askUserForArrangementChoice = () => {
    return new Promise((resolve) => {
      let selectedArrangement = "arrangement1";
      Modal.confirm({
        title: "Choose Arrangement",
        content: (
          <div>
            <p>Please choose an arrangement:</p>
            <Select
              defaultValue="arrangement1"
              style={{ width: 200 }}
              onChange={(value) => (selectedArrangement = value)}
            >
              <Option value="arrangement1">Arrangement 1</Option>
              <Option value="arrangement2">Arrangement 2</Option>
            </Select>
          </div>
        ),
        onOk: () => {
          if (selectedArrangement !== null) {
            resolve(selectedArrangement);
          }
        },
        cancelButtonProps: { style: { display: "none" } },
      });
    });
  };

  const showImageNotExistMessage = (filePath) => {
    Modal.info({
      title: "Image Not Imported",
      content: (
        <div>
          <p>
            Image at the below path is not imported, please use import image
            button to upload the image{" "}
          </p>
          <strong>{filePath}</strong>
        </div>
      ),
      okText: "OK",
    });
  };

  const handleImageSaveCopy = async (
    userId,
    fileID,
    imageUrl,
    newLidarInfo
  ) => {
    try {
      const response = await fetch(imageUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch image. Status: ${response.status}`);
      }

      const blob = await response.blob();

      const binaryData = await new Response(blob).arrayBuffer();
      const referenceImageFileName = `${userId}/saveReferenceImage_${fileID}.png`;

      const putUrl = await AuthPostData(endPoints.uploadFiles, {
        file: `sct/dev/${referenceImageFileName}`,
      });

      await fetch(putUrl.url, {
        method: "PUT",
        body: binaryData,
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      return {
        ...newLidarInfo,
        plotSettings: {
          ...newLidarInfo.plotSettings,
          "reference image": {
            ...newLidarInfo.plotSettings["reference image"],
            filename: `sct/dev/${referenceImageFileName}`,
          },
        },
      };
    } catch (error) {
      message.error("Failed to save image data");
      return null; // Indicate failure by returning null or some other value
    }
  };

  const handleCustomRequest = ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      let profileData = Cookies.get("profileData");
      profileData = JSON.parse(profileData);

      if (profileData) {
        try {
          setLoading(true);
          const uploadedJson = JSON.parse(event.target.result);
          const validationSchema =
            uploadedJson?.sensorsSettings?.arrangement2?.length !== 0
              ? Arrangement2ValidateJSON
              : Arrangement1ValidateJSON;
          if (
            compareJsonStructure(validationSchema, uploadedJson) &&
            validateRecursive(uploadedJson) &&
            uploadedJson.simulationSettings.ground_offset <
              uploadedJson.simulationSettings.tracked_object_height
          ) {
            if (uploadedJson.sensorsSettings.arrangement2.length !== 0) {
              const userChoice = await askUserForArrangementChoice();

              if (userChoice === "arrangement1") {
                uploadedJson.sensorsSettings.arrangement2 = [];
              } else {
                uploadedJson.sensorsSettings.arrangement1 =
                  uploadedJson.sensorsSettings.arrangement2;
                uploadedJson.plotSettings.arrangement1 = JSON.parse(
                  JSON.stringify(uploadedJson.plotSettings.arrangement2)
                );

                uploadedJson.sensorsSettings.arrangement2 = [];
              }

              delete uploadedJson.plotSettings.arrangement2.beam_coverage.range;
              delete uploadedJson.plotSettings.arrangement2.point_coverage
                .range;
            }

            const lastDotIndex = file.name.lastIndexOf(".");
            const fileNameWithoutExtension =
              lastDotIndex !== -1
                ? file.name.slice(0, lastDotIndex)
                : file.name;

            if (fileNameWithoutExtension.length > 220) {
              message.error("File name size is more than 220 characters");
              return;
            }

            const newUploadedJson = JSON.parse(JSON.stringify(uploadedJson));

            newUploadedJson.plotSettings["reference image"].filename = "";

            const result = await AuthPostData(endPoints.sensorData, {
              email: profileData.email,
              fileName: fileNameWithoutExtension,
              ...newUploadedJson,
            });

            if (checkTheImagePath(uploadedJson)) {
              const filPath =
                uploadedJson.plotSettings["reference image"].filename;
              showImageNotExistMessage(filPath);
            } else {
              if (
                uploadedJson.plotSettings[
                  "reference image"
                ].filename.startsWith("sct")
              ) {
                const getBucketUrl = await AuthGetData(endPoints.getS3Url);

                const imageUrl =
                  getBucketUrl.data +
                  uploadedJson.plotSettings["reference image"].filename;
                const newLidarInfo = await handleImageSaveCopy(
                  profileData.userId,
                  result.userFileId,
                  imageUrl,
                  uploadedJson
                );

                if (newLidarInfo !== null) {
                  await AuthPostData(endPoints.sensorData, {
                    email: profileData.email,
                    fileName: fileNameWithoutExtension,
                    userFileId: result.userFileId,
                    ...newLidarInfo,
                  });
                }
              }
            }

            message.success(result.msg);
            const encodedKey = result.userFileId
              ? btoa(result.userFileId)
              : null;

            const queryString = `?fileToken=${encodedKey}`;
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }

            navigate(`/graphplotScreen${queryString}`);
          } else {
            message.error("File format is incorrect");
          }
        } catch (errors) {
          const error = errors.error || "File format is incorrect";
          message.error(error);
        } finally {
          setLoading(false);
        }
      }

      onSuccess();
    };
    reader.readAsText(file);
  };

  const handleBeforeUpload = (file) => {
    const isJsonFile = file.type === "application/json";
    if (!isJsonFile) {
      message.error("You can only upload JSON files!");
    }
    return isJsonFile;
  };

  function findNextAvailableCopyNumber(baseFilename, existingFilenames) {
    let copyNumber = 1;

    while (
      existingFilenames.includes(`${baseFilename} - copy (${copyNumber})`)
    ) {
      copyNumber++;
    }

    return copyNumber;
  }

  const handleCopy = async (record) => {
    let profileData = Cookies.get("profileData");
    profileData = JSON.parse(profileData);

    if (profileData) {
      try {
        setLoading(true);
        const result = await AuthPostData(endPoints.getSensorData, {
          email: profileData.email,
          userFileId: record.key,
        });
        const jsonData = result.lidarInfo;

        const dataFilenames = data.map((item) => item.file);
        const baseFilename = record.name;

        const newCopyNumber = findNextAvailableCopyNumber(
          baseFilename,
          dataFilenames
        );

        const newFilename = `${baseFilename} - copy (${newCopyNumber})`;

        if (newFilename.length > 500) {
          message.error("File name size is more than 500 characters");
          return;
        }

        const resultData = await AuthPostData(endPoints.sensorData, {
          email: profileData.email,
          fileName: newFilename,
          ...jsonData,
        });

        if (jsonData.plotSettings["reference image"].filename !== "") {
          const newLidarInfo = await handleImageSaveCopy(
            profileData.userId,
            resultData.userFileId,
            jsonData.plotSettings["reference image"].filename,
            jsonData
          );
          if (newLidarInfo !== null) {
            await AuthPostData(endPoints.sensorData, {
              email: profileData.email,
              fileName: newFilename,
              userFileId: resultData.userFileId,
              ...newLidarInfo,
            });
          }
        }

        message.success(resultData.msg);
        fetchData();
      } catch (errors) {
        const error =
          errors.error ||
          "Unable to connect with the server, please try again after some time";
        message.error(error);
      }
    } else {
      message.error("Something went wrong please relogin");
      navigate("login");
    }
  };

  const handleDelete = async (key) => {
    try {
      setLoading(true);
      const result = await AuthDeleteData(endPoints.getUserFiles, {
        userFileId: key,
      });

      if (result.status === 405) {
        message.error(result.data.error);
      } else {
        message.success(result.message);
      }
      fetchData();
    } catch (errors) {
      const error =
        errors.error ||
        "Unable to connect with the server, please try again after some time";
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUnsavedChanges = () => {
    return;
  };

  const handleEdit = (record) => {
    const encodedKey = record.key ? btoa(record.key) : null;
    const queryString = `?fileToken=${encodedKey}`;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    navigate(`/graphplotScreen${queryString}`);
  };

  return (
    <>
      {
        <div className="home-screen-layout">
          <GlobalHeader
            checkUnsavedChanges={false}
            handleUnsavedChanges={handleUnsavedChanges}
          ></GlobalHeader>
          {loading ? (
            <div
              className="loader-container"
              style={{ display: loading ? "flex" : "none" }}
            >
              <Spin size="large" tip="Loading">
                <span></span>
              </Spin>
            </div>
          ) : data === null || data?.length === 0 ? (
            <SettingNotExist
              customRequest={handleCustomRequest}
              beforeUpload={handleBeforeUpload}
              handleCreateButton={handleCreateButton}
            />
          ) : (
            <SettingExist
              userFiles={data}
              customRequest={handleCustomRequest}
              beforeUpload={handleBeforeUpload}
              onCopy={handleCopy}
              onDelete={handleDelete}
              handleCreateButton={handleCreateButton}
              handleEdit={handleEdit}
            />
          )}
        </div>
      }
    </>
  );
}

export default HomeScreen;
