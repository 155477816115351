import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Cookies from "js-cookie";
import VerifyOtpScreen from "../screens/VerifyOtpScreen";
import LoginScreen from "../screens/LoginScreen";
import GraphPlotScreen from "../screens/GraphPlotScreen";
import HomeScreen from "../screens/HomeScreen";

const isAuthenticated = () => {
  const authToken = Cookies.get("authToken");
  const userProfile = Cookies.get("profileData");
  return authToken && userProfile;
};

function AllRoutes() {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            isAuthenticated() ? <Navigate to="/homescreen" /> : <LoginScreen />
          }
        />
        <Route
          path="/verifyotp"
          element={
            isAuthenticated() ? (
              <Navigate to="/homescreen" />
            ) : (
              <VerifyOtpScreen />
            )
          }
        />
        <Route
          path="/homescreen"
          element={
            isAuthenticated() ? <HomeScreen /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/graphplotScreen"
          element={
            isAuthenticated() ? <GraphPlotScreen /> : <Navigate to="/login" />
          }
        />
        <Route path="*" element={<Navigate to="/login" />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default AllRoutes;
