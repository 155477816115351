import React, { useEffect, useState } from "react";
import { Modal, Menu, Spin, Typography, Slider, message } from "antd";
import plotVisualise from "../python/PlotVisualise.py";
import "./GenerateGraphModal.css";
import { removeCanvasElement } from "../utils/removeCanvasElement";
const { Text } = Typography;

async function loadPyodide() {
  return new Promise(async (resolve) => {
    const pyodideInstance = await window.loadPyodide({
      indexURL: "https://cdn.jsdelivr.net/pyodide/v0.24.1/full/",
    });
    await pyodideInstance.loadPackage("micropip");
    await pyodideInstance.loadPackage("matplotlib");
    pyodideInstance.runPythonAsync(`
    import micropip
    await micropip.install('requests','idna')
    `);
    resolve(pyodideInstance);
  });
}

const loadPyodides = loadPyodide();

function GenerateGraphModel({
  isVisible,
  handleOk,
  handleCancel,
  isOpacitySliderVisible,
  image,
  pointsPerCell,
  beamsPerCell,
  lidarInfo,
  fileName,
}) {
  const [activeTab, setActiveTab] = useState("1");
  const [referenceImageOpacity, setReferenceImageOpacity] = useState(100);
  const [coveragePlotOpacity, setCoveragePlotOpacity] = useState(100);
  const [output, setOutput] = useState(false);
  const [loading, setLoading] = useState(false);

  const runScript = async (code) => {
    loadPyodides
      .then((loadedPyodide) => {
        let pyodide = loadedPyodide;
        pyodide.globals.set("last_json", lidarInfo);
        pyodide.globals.set("beamsPerCell", beamsPerCell);
        pyodide.globals.set("pointsPerCell", pointsPerCell);
        pyodide.globals.set("image_data_url", image);
        pyodide.globals.set("plotType", activeTab);
        pyodide.globals.set("plotAlpha", coveragePlotOpacity);
        pyodide.globals.set("imageAlpha", referenceImageOpacity);
        pyodide.runPython(code);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    let divElements = document.querySelectorAll('div[id^="matplotlib"]');
    divElements.forEach(function (element) {
      element.parentNode?.removeChild(element);
    });

    const run = async () => {
      let scriptText = await (await fetch(plotVisualise)).text();
      await runScript(scriptText);

      setOutput(true);
      setLoading(false);
    };

    run();
  }, [activeTab, referenceImageOpacity, coveragePlotOpacity]);

  const handleMenuClick = (key) => {
    setActiveTab(key);
    setOutput(false);
  };

  const handleReferenceImageOpacityChange = (value) => {
    setReferenceImageOpacity(value);
    setOutput(false);
  };

  const handleCoveragePlotOpacityChange = (value) => {
    setCoveragePlotOpacity(value);
    setOutput(false);
  };

  return (
    <>
      <Modal
        title="Generated Graphs"
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="generated-model-outer-layout"
        width="auto"
        height="auto"
        centered
        footer={null}
      >
        <div
          className="loader-container"
          style={{ display: loading ? "flex" : "none" }}
        >
          <Spin size="large" tip="Loading">
            <span></span>
          </Spin>
        </div>
        <div className="generated-graph-content-layout">
          <div className="generated-model-body-content-layout">
            <Menu
              mode="vertical"
              selectedKeys={[activeTab]}
              onClick={({ key }) => handleMenuClick(key)}
            >
              <Menu.Item key="1" className="generated-model-menu-item">
                Beam Coverage
              </Menu.Item>
              <Menu.Item key="2" className="generated-model-menu-item">
                Beam Histogram
              </Menu.Item>
              <Menu.Item key="3" className="generated-model-menu-item">
                Point Coverage
              </Menu.Item>
              <Menu.Item key="4" className="generated-model-menu-item">
                Point Histogram
              </Menu.Item>
            </Menu>
          </div>
          <div className="generated-model-graph-plot-area-outer-layout">
            <div
              className="generated-model-graph-plot"
              id="generated-model-graph-plot-area"
            >
              {(() => {
                let tabSuffix = "";
                switch (activeTab) {
                  case "1":
                    tabSuffix = "_beamcoverage";
                    break;
                  case "2":
                    tabSuffix = "_beamhistogram";
                    break;
                  case "3":
                    tabSuffix = "_pointcoverage";
                    break;
                  case "4":
                    tabSuffix = "_pointhistogram";
                    break;
                }

                return output === false
                  ? null
                  : removeCanvasElement(
                      "generated-model-graph-plot-area",
                      fileName + tabSuffix
                    );
              })()}
            </div>
            {!isOpacitySliderVisible &&
            activeTab !== "2" &&
            activeTab !== "4" ? (
              <div className="generated-model-footer-outer-layout">
                <div className="generated-model-footer-button-outer-layout">
                  <div className="generated-model-footer-button-content-layout">
                    <div className="adjust-slider-outer-wrapper">
                      <Text className="adjust-slider-label-text">
                        Reference Image Opacity
                      </Text>
                      <div style={{ width: "120px" }}>
                        <Slider
                          min={0}
                          max={100}
                          step={1}
                          defaultValue={referenceImageOpacity}
                          onChange={handleReferenceImageOpacityChange}
                        />
                      </div>
                    </div>
                    <div className="adjust-slider-outer-wrapper">
                      <Text className="adjust-slider-label-text">
                        Coverage Plot Opacity
                      </Text>
                      <div style={{ width: "120px" }}>
                        <Slider
                          min={0}
                          max={100}
                          step={1}
                          defaultValue={coveragePlotOpacity}
                          onChange={handleCoveragePlotOpacityChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default GenerateGraphModel;
