const Defaultjson = {
  sensorsSettings: {
    arrangement1: [],
    arrangement2: [],
  },
  simulationSettings: {
    ground_offset: 0.25,
    tracked_object_height: 1.75,
    obstacles: [],
    total_area: {
      min_x: -40,
      max_x: 40,
      min_y: -40,
      max_y: 40,
    },
    sample_size: {
      length: 1,
      width: 1,
    },
  },
  plotSettings: {
    "reference image": {
      filename: "",
      origin: {
        x: 0.0,
        y: 0.0,
      },
      rotation: 0.0,
      scale: 1.0,
      alpha: 1.0,
    },
    "image prefix": "./test/",
    arrangement1: {
      ring: {
        enabled: "true",
        sensorsFilledSetting: {
          sensor1: {
            color: "magenta",
            alpha: 0.2,
          },
        },
        "save png": "false",
      },
      beam_coverage: {
        enabled: "true",
        "discrete colors": "false",
        "save png": "false",
        range: {
          enabled: "false",
          min: 0,
          max: 3,
        },
      },
      beam_histogram: {
        enabled: "true",
        "save png": "false",
      },
      point_coverage: {
        enabled: "true",
        "discrete colors": "false",
        "save png": "false",
        range: {
          enabled: "false",
          min: 0,
          max: 100,
        },
      },
      point_histogram: {
        enabled: "true",
        "save png": "false",
      },
    },
    arrangement2: {
      ring: {
        enabled: "true",
        sensorsFilledSetting: {
          sensor1: {
            color: "magenta",
            alpha: 0.2,
          },
        },
        "save png": "false",
      },
      beam_coverage: {
        enabled: "true",
        "discrete colors": "false",
        "save png": "false",
      },
      beam_histogram: {
        enabled: "true",
        "save png": "false",
      },
      point_coverage: {
        enabled: "true",
        "discrete colors": "false",
        "save png": "false",
      },
      point_histogram: {
        enabled: "true",
        "save png": "false",
      },
    },
    comparison: {
      "beam_coverage comparison": {
        enabled: "false",
        "discrete colors": "false",
        "save png": "false",
      },
      "point_coverage comparison": {
        enabled: "false",
        "discrete colors": "false",
        "save png": "false",
      },
    },
  },
};
export default Defaultjson;
