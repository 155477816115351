import React, { useState, useEffect } from "react";
import {
  Button,
  Tabs,
  Typography,
  Checkbox,
  InputNumber,
  Tooltip,
  Modal,
} from "antd";
import "./SideBar.css";
import SensorComponent from "./SensorComponent";
import ObstacleComponent from "./ObstacleComponent";
import { useSelector, useDispatch } from "react-redux";
import { selectLidarInfo, setLidarInfo } from "../redux/LidarSlice";
const { TabPane } = Tabs;
const { Text } = Typography;

function SideBar({ toggleFlag }) {
  const lidarInfo = useSelector(selectLidarInfo);
  const dispatch = useDispatch();
  const [activeSensorIndex, setActiveSensorIndex] = useState(null);
  const [activeObstacleIndex, setActiveObstacleIndex] = useState(null);
  const [discreteColors, setDiscreteColors] = useState(
    lidarInfo?.plotSettings?.arrangement1?.beam_coverage["discrete colors"] ===
      "true"
  );
  const [useRangeBeamCoverage, setUseRangeBeamCoverage] = useState(
    lidarInfo?.plotSettings?.arrangement1?.beam_coverage?.range.enabled ===
      "true"
  );
  const [discreteColorsPointCoverage, setDiscreteColorsPointCoverage] =
    useState(
      lidarInfo?.plotSettings.arrangement1.point_coverage["discrete colors"] ===
        "true"
    );

  const [useRangePointCoverage, setUseRangePointCoverage] = useState(
    lidarInfo?.plotSettings?.arrangement1?.point_coverage?.range.enabled ===
      "true"
  );

  const [activeIndex, setActiveIndex] = useState("1");

  useEffect(() => {
    const beamCoverageDiscreteColors =
      typeof lidarInfo?.plotSettings?.arrangement1?.beam_coverage[
        "discrete colors"
      ] === "string"
        ? lidarInfo?.plotSettings?.arrangement1?.beam_coverage[
            "discrete colors"
          ].toLowerCase() === "true"
        : lidarInfo?.plotSettings?.arrangement1?.beam_coverage[
            "discrete colors"
          ];
    const beamCoverageRange =
      typeof lidarInfo?.plotSettings?.arrangement1?.beam_coverage?.range
        .enabled === "string"
        ? lidarInfo?.plotSettings?.arrangement1?.beam_coverage?.range.enabled.toLowerCase() ===
          "true"
        : lidarInfo?.plotSettings?.arrangement1?.beam_coverage?.range.enabled;
    const pointCoverageDiscreteColors =
      typeof lidarInfo?.plotSettings?.arrangement1?.point_coverage[
        "discrete colors"
      ] === "string"
        ? lidarInfo?.plotSettings?.arrangement1?.point_coverage[
            "discrete colors"
          ].toLowerCase() === "true"
        : lidarInfo?.plotSettings?.arrangement1?.point_coverage[
            "discrete colors"
          ];
    const pointCoverageRange =
      typeof lidarInfo?.plotSettings?.arrangement1?.point_coverage?.range
        ?.enabled === "string"
        ? lidarInfo?.plotSettings?.arrangement1?.point_coverage?.range?.enabled.toLowerCase() ===
          "true"
        : lidarInfo?.plotSettings?.arrangement1?.point_coverage?.range?.enabled;

    setDiscreteColors(beamCoverageDiscreteColors);
    setUseRangeBeamCoverage(beamCoverageRange);
    setDiscreteColorsPointCoverage(pointCoverageDiscreteColors);
    setUseRangePointCoverage(pointCoverageRange);
  }, [lidarInfo?.plotSettings?.arrangement1]);

  const handleSensorClick = (index, status) => {
    if (!status) {
      setActiveSensorIndex(index === activeSensorIndex ? null : index);
    } else {
      setActiveSensorIndex(index);
    }
    setActiveIndex("2");
  };

  const handleObstacleClick = (index, status) => {
    if (!status) {
      setActiveObstacleIndex(index === activeObstacleIndex ? null : index);
    } else {
      setActiveObstacleIndex(index);
    }

    setActiveIndex("3");
  };

  window.handleSelectSensor = async (selectedJson) => {
    var parsedJson = await JSON.parse(selectedJson);
    if (parsedJson.isSensor === "false") {
      handleObstacleClick(parsedJson.selected_obstacle_index, true);
    } else if (parsedJson.isSensor === "true") {
      handleSensorClick(parsedJson.selected_sensor_index, true);
    }
  };

  const updateSensorData = (index, key, value) => ({
    ...lidarInfo,
    sensorsSettings: {
      ...lidarInfo.sensorsSettings,
      arrangement1: lidarInfo.sensorsSettings.arrangement1.map(
        (sensor, sensorIndex) =>
          sensorIndex === index
            ? {
                ...sensor,
                [key]: value,
              }
            : sensor
      ),
    },
  });

  const handleUpdateSensorData = (index, key, value) => {
    const updatedLidarInfo = updateSensorData(index, key, value);
    dispatch(setLidarInfo(updatedLidarInfo));
  };

  const updateObstacleData = (index, key, value) => ({
    ...lidarInfo,
    simulationSettings: {
      ...lidarInfo.simulationSettings,
      obstacles: lidarInfo.simulationSettings.obstacles.map((obstacle, i) =>
        i === index
          ? {
              ...obstacle,
              [key]: value,
            }
          : obstacle
      ),
    },
  });

  const handleUpdateObstacleData = (index, key, value) => {
    const updatedLidarInfo = updateObstacleData(index, key, value);
    dispatch(setLidarInfo(updatedLidarInfo));
  };

  const handleSensorDelete = (indexToDelete) => {
    const updatedSensors = lidarInfo.sensorsSettings.arrangement1.filter(
      (_, index) => index !== indexToDelete
    );

    dispatch(
      setLidarInfo({
        ...lidarInfo,
        sensorsSettings: {
          ...lidarInfo.sensorsSettings,
          arrangement1: updatedSensors,
        },
      })
    );

    handleSensorClick(indexToDelete, false);
    toggleFlag();
  };

  const handleObstacleDelete = (indexToDelete) => {
    const updatedObstacles = lidarInfo.simulationSettings.obstacles.filter(
      (_, index) => index !== indexToDelete
    );

    dispatch(
      setLidarInfo({
        ...lidarInfo,
        simulationSettings: {
          ...lidarInfo.simulationSettings,
          obstacles: updatedObstacles,
        },
      })
    );

    toggleFlag();
    setActiveObstacleIndex(indexToDelete);
  };

  const handleAddSensor = () => {
    const newSensor = {
      sensorId: "unnamed",
      type: "mq8",
      range: 85,
      x: 0.0,
      y: 0.0,
      z: 3.25,
      yaw_angle: 90,
      pitch_angle: 0,
      roll_angle: 0,
      min_angle: 180,
      max_angle: 180,
      frame: "world",
      spin_rate: 10,
      use_intrinsic: true,
    };

    dispatch(
      setLidarInfo({
        ...lidarInfo,
        sensorsSettings: {
          ...lidarInfo.sensorsSettings,
          arrangement1: [...lidarInfo.sensorsSettings.arrangement1, newSensor],
        },
      })
    );

    toggleFlag();
  };

  const handleAddObstacle = () => {
    // Add a new obstacle with default values to the lidarInfo state
    const newObstacle = {
      center: [0, 0, 0],
      length: 10,
      width: 10,
      height: 2,
      rotation: 0,
    };

    // Update the lidarInfo state
    setLidarInfo((prevLidarInfo) => ({
      ...prevLidarInfo,
      simulationSettings: {
        ...prevLidarInfo.simulationSettings,
        obstacles: [...prevLidarInfo.simulationSettings.obstacles, newObstacle],
      },
    }));

    dispatch(
      setLidarInfo({
        ...lidarInfo,
        simulationSettings: {
          ...lidarInfo.simulationSettings,
          obstacles: [...lidarInfo.simulationSettings.obstacles, newObstacle],
        },
      })
    );

    toggleFlag();
  };

  const isValidNumber = (value, min, max, precision) => {
    if (value === "") {
      return true; // Allow an empty value
    }

    const numericValue = Number(value);

    if (numericValue < min || numericValue > max) {
      return false;
    }

    // Check if the numeric value has the correct precision
    const valueString = String(value);
    const decimalIndex = valueString.indexOf(".");
    const decimalPlaces =
      decimalIndex === -1 ? 0 : valueString.length - decimalIndex - 1;

    return decimalPlaces <= precision;
  };

  const handleEvaluationAreaOnKeyDown = (event) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      "-",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    const inputValue = event.target.value;
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    if (inputValue.startsWith("0") && event.key === "0" && selectionStart === 1) {
      event.preventDefault();
    }

    // Handle Backspace and Delete keys
    if (event.key === "Backspace" || event.key === "Delete") {
      // Allow the default behavior for Backspace and Delete keys if no selection
      if (selectionStart === selectionEnd) {
        return;
      }
    }

    // If the user is moving the cursor (ArrowLeft or ArrowRight), allow it
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      return;
    }

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    // Check if the pressed key is a dot or minus sign
    if (event.key === "." && inputValue.includes(".")) {
      event.preventDefault();
      return;
    }

    // Check if the pressed key is a minus sign
    if (event.key === "-") {
      // If the cursor is not at the beginning or there's already a minus sign, prevent entry
      if (selectionStart !== 0 || inputValue.includes("-")) {
        event.preventDefault();
        return;
      }
    }

    if (inputValue.includes("-") && selectionStart === 0) {
      event.preventDefault();
      return;
    }

    if (event.key === "0" && selectionStart > 0) {
      // If there's already a zero at the current cursor position, prevent entry

      if (
        inputValue.charAt(selectionStart - 1) === "-" &&
        inputValue.charAt(selectionStart - 2) !== "."
      ) {
        event.preventDefault();
        return;
      }
    }

    // Replace the selected part of the input value with the pressed key
    const newValue =
      inputValue.substring(0, selectionStart) +
      event.key +
      inputValue.substring(selectionEnd);

    // Check if the new value is a valid number within the allowed range and precision
    if (!isValidNumber(newValue, -10000.0, 10000.0, 2)) {
      event.preventDefault();
    }
  };

  const handleSimulationGraphOnKeyDown = (event, minValue, maxValue) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      ".",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
      "Enter",
    ];

    // Check if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
      return;
    }

    const inputValue = event.target.value;
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    if (inputValue.startsWith("0") && event.key === "0" && selectionStart === 1) {
      event.preventDefault();
    }

    // Handle Backspace and Delete keys
    if (event.key === "Backspace" || event.key === "Delete") {
      // Allow the default behavior for Backspace and Delete keys if no selection
      if (selectionStart === selectionEnd) {
        return;
      }
    }

    // If the user is moving the cursor (ArrowLeft or ArrowRight), allow it
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      return;
    }

    // Replace the selected part of the input value with the pressed key
    const newValue =
      inputValue.substring(0, selectionStart) +
      event.key +
      inputValue.substring(selectionEnd);

    // Check if the new value is a valid number within the allowed range and precision
    if (!isValidNumber(newValue, minValue, maxValue, 2)) {
      event.preventDefault();
    }
  };

  const handleCoveragePlotOnKeyDown = (event) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",

      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    // Check if the entered value is within the allowed range
    const currentValue = event.target.value;

    if (
      currentValue.startsWith("0") &&
      event.key === "0" &&
      event.target.selectionStart === 1
    ) {
      event.preventDefault();
    }

    const numericValue = parseInt(currentValue + event.key);

    if (isNaN(numericValue) || numericValue < 0 || numericValue > 1000) {
      event.preventDefault();
    }

    // Allow only if the pressed key is in the allowedKeys array
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const handleEvaluationAreaInputStep = (fieldName, value) => {
    const currentValue =
      parseFloat(lidarInfo?.simulationSettings?.total_area?.[fieldName]) -
      value;
    const newValue =
      parseFloat(lidarInfo?.simulationSettings?.total_area?.[fieldName]) -
      currentValue;

    if (newValue >= -10000.0 && newValue <= 10000.0) {
      dispatch(
        setLidarInfo({
          ...lidarInfo,
          simulationSettings: {
            ...lidarInfo.simulationSettings,
            total_area: {
              ...lidarInfo.simulationSettings.total_area,
              [fieldName]: newValue,
            },
          },
        })
      );
    }
  };

  const handleEvaluationAreaInputChange = (fieldName, event) => {
    const value = Number(event.target.value);

    if (
      event.target.value !== "" &&
      !isNaN(value) &&
      value >= -10000.0 &&
      value <= 10000.0
    ) {
      dispatch(
        setLidarInfo({
          ...lidarInfo,
          simulationSettings: {
            ...lidarInfo.simulationSettings,
            total_area: {
              ...lidarInfo.simulationSettings.total_area,
              [fieldName]: value,
            },
          },
        })
      );
    }

    toggleFlag();
  };

  const deleteSensorsWithPositionOutOfRange = () => {
    const sensors = lidarInfo?.sensorsSettings?.arrangement1 || [];

    // Find sensors where x or y values are greater than 10000
    const sensorsToDelete = sensors.filter(
      (sensorData) =>
        sensorData.x > 10000.0 ||
        sensorData.y > 10000.0 ||
        sensorData.x < -10000.0 ||
        sensorData.y < -10000.0
    );

    // Call handleSensorDelete for each sensor to be deleted

    if (sensorsToDelete.length !== 0) {
      Modal.warning({
        content: "Adding sensor outside -10000 to 10000 range is not allowed",
        centered: true,
        onOk() {},
      });
    }
    sensorsToDelete.forEach((sensorData, index) => {
      const indexToDelete = sensors.indexOf(sensorData);
      if (indexToDelete !== -1) {
        handleSensorDelete(indexToDelete);
      }
    });
  };

  const deleteObstaclesWithPositionOutOfRange = () => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    const obstacles = lidarInfo?.simulationSettings?.obstacles || [];

    // Find obstacles where x or y values are greater than 10000
    const obstaclesToDelete = obstacles.filter(
      (obstacleData) =>
        obstacleData.center[0] > 10000.0 ||
        obstacleData.center[1] > 10000.0 ||
        obstacleData.center[0] < -10000.0 ||
        obstacleData.center[1] < -10000.0
    );

    // Show warning modal if there are obstacles out of range
    if (obstaclesToDelete.length !== 0) {
      Modal.warning({
        content: "Adding obstacle outside -10000 to 10000 range is not allowed",
        centered: true,
        onOk() {},
      });
    }

    // Call handleObstacleDelete for each obstacle to be deleted
    obstaclesToDelete.forEach((obstacleData, index) => {
      const indexToDelete = obstacles.indexOf(obstacleData);
      if (indexToDelete !== -1) {
        handleObstacleDelete(indexToDelete);
      }
    });

    // Remove the event listener using the same function reference
    document.removeEventListener("contextmenu", (event) => event.preventDefault());
  };


  return (
    <div className="sidebar-outer-layout">
      <div className="side-bar-top-nav-layout">
        <div className="tab-bar-wrapper-outer">
          <div className="tab-bar-wrapper">
            <Tabs
              defaultActiveKey="1"
              activeKey={activeIndex}
              onChange={(key) => setActiveIndex(key)}
              centered
            >
              <TabPane
                tab={
                  <Tooltip title="The area for which the graphs get calculated">
                    Evaluation Area
                  </Tooltip>
                }
                key="1"
              >
                <div className="tab-fields-area">
                  <div className="tab-form-area">
                    <div className="fields-outer-layout">
                      <Text className="label-text">Minimum</Text>
                      <div className="fileds-value-outer-layout">
                        <div className="value-layout">
                          <Text className="value-label">X</Text>
                          <InputNumber
                            min={-10000.0}
                            max={10000.0}
                            step={1}
                            precision={2}
                            value={
                              lidarInfo?.simulationSettings?.total_area?.min_x
                            }
                            onBlur={(event) =>
                              handleEvaluationAreaInputChange("min_x", event)
                            }
                            onStep={(value) =>
                              handleEvaluationAreaInputStep("min_x", value)
                            }
                            onKeyDown={handleEvaluationAreaOnKeyDown}
                          />
                          <Text className="value-text">m</Text>
                        </div>
                        <div className="value-layout">
                          <Text className="value-label">Y</Text>
                          <InputNumber
                            min={-10000.0}
                            max={10000.0}
                            step={1}
                            precision={2}
                            value={
                              lidarInfo?.simulationSettings?.total_area?.min_y
                            }
                            onBlur={(event) =>
                              handleEvaluationAreaInputChange("min_y", event)
                            }
                            onStep={(value) =>
                              handleEvaluationAreaInputStep("min_y", value)
                            }
                            onKeyDown={handleEvaluationAreaOnKeyDown}
                          />
                          <Text className="value-text">m</Text>
                        </div>
                      </div>
                    </div>
                    <div className="fields-outer-layout">
                      <Text className="label-text">Maximum</Text>
                      <div className="fileds-value-outer-layout">
                        <div className="value-layout">
                          <Text className="value-label">X</Text>
                          <InputNumber
                            min={-10000.0}
                            max={10000.0}
                            step={1} // Set the step to control decimal places
                            precision={2}
                            value={
                              lidarInfo?.simulationSettings?.total_area?.max_x
                            }
                            onBlur={(event) =>
                              handleEvaluationAreaInputChange("max_x", event)
                            }
                            onStep={(value) =>
                              handleEvaluationAreaInputStep("max_x", value)
                            }
                            onKeyDown={handleEvaluationAreaOnKeyDown}
                          />
                          <Text className="value-text">m</Text>
                        </div>
                        <div className="value-layout">
                          <Text className="value-label">Y</Text>
                          <InputNumber
                            min={-10000.0}
                            max={10000.0}
                            step={1} // Set the step to control decimal places
                            precision={2}
                            value={
                              lidarInfo?.simulationSettings?.total_area?.max_y
                            }
                            onBlur={(event) =>
                              handleEvaluationAreaInputChange("max_y", event)
                            }
                            onStep={(value) =>
                              handleEvaluationAreaInputStep("max_y", value)
                            }
                            onKeyDown={handleEvaluationAreaOnKeyDown}
                          />
                          <Text className="value-text">m</Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>

              <TabPane
                tab={
                  <Tooltip title="Add and update sensor properties in the scene">
                    Sensors
                  </Tooltip>
                }
                key="2"
              >
                <div className="tab-fields-area">
                  <Button
                    className="add-sensor-obstacle-button"
                    onClick={handleAddSensor}
                  >
                    Add Sensor
                  </Button>

                  <div className="custom-scroll-container">
                    {deleteSensorsWithPositionOutOfRange()}

                    {lidarInfo?.sensorsSettings?.arrangement1?.map(
                      (sensorData, index) => (
                        <SensorComponent
                          key={index}
                          sensorName={`Sensor${index + 1}`}
                          sensorData={sensorData}
                          isActive={index === activeSensorIndex}
                          onDelete={() => handleSensorDelete(index)}
                          onClick={(status) => {
                            handleSensorClick(index, status);
                          }}
                          onInputChange={(key, value) => {
                            handleUpdateSensorData(index, key, value);
                          }}
                          toggleFlag={toggleFlag}
                        />
                      )
                    )}
                  </div>
                </div>
              </TabPane>

              <TabPane
                tab={
                  <Tooltip title="Add and update object properties in the scene">
                    Obstacles
                  </Tooltip>
                }
                key="3"
              >
                <div className="tab-fields-area">
                  <Button
                    className="add-sensor-obstacle-button"
                    onClick={handleAddObstacle}
                  >
                    Add Obstacle
                  </Button>

                  <div className="custom-scroll-container">
                    {deleteObstaclesWithPositionOutOfRange()}
                    {lidarInfo?.simulationSettings?.obstacles?.map(
                      (obstacleData, index) => (
                        <ObstacleComponent
                          key={index}
                          obstacleName={`Obstacle${index + 1}`}
                          obstacleData={obstacleData}
                          isActive={index === activeObstacleIndex}
                          onDelete={() => handleObstacleDelete(index)}
                          onClick={(status) =>
                            handleObstacleClick(index, status)
                          }
                          onInputChange={(key, value) => {
                            handleUpdateObstacleData(index, key, value);
                          }}
                          toggleFlag={toggleFlag}
                        />
                      )
                    )}
                  </div>
                </div>
              </TabPane>

              <TabPane
                tab={
                  <Tooltip title="Change simulation/graph properties">
                    Simulation/Graph
                  </Tooltip>
                }
                key="4"
              >
                <div className="simulator-graph-area">
                  <div className="simulation-field">
                    <div className="fields-outer-layout">
                      <div className="fileds-value-outer-layout">
                        <div className="value-layout">
                          <Text className="value-label">Ground Offset</Text>
                          <InputNumber
                            min={0.0}
                            max={100.0}
                            step={1} // Set the step to control decimal places
                            precision={2}
                            value={lidarInfo?.simulationSettings?.ground_offset}
                            onBlur={(event) => {
                              const value = Number(event.target.value);
                              if (
                                event.target.value !== "" &&
                                value >= 0.0 &&
                                value <= 100.0 &&
                                value <
                                  lidarInfo?.simulationSettings
                                    ?.tracked_object_height
                              ) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    simulationSettings: {
                                      ...lidarInfo.simulationSettings,
                                      ground_offset: value,
                                    },
                                  })
                                );
                              } else if (event.target.value !== "") {
                                Modal.warning({
                                  content:
                                    "Ground Offset must be less than Tracking Object Height",
                                  onOk() {},
                                });
                              }
                              toggleFlag();
                            }}
                            onStep={(value) => {
                              const currentValue =
                                parseFloat(
                                  lidarInfo?.simulationSettings?.ground_offset
                                ) - value;
                              const newValue =
                                parseFloat(
                                  lidarInfo?.simulationSettings?.ground_offset
                                ) - currentValue;

                              if (
                                newValue >= 0.0 &&
                                newValue <= 100.0 &&
                                newValue <
                                  lidarInfo?.simulationSettings
                                    ?.tracked_object_height
                              ) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    simulationSettings: {
                                      ...lidarInfo.simulationSettings,
                                      ground_offset: newValue,
                                    },
                                  })
                                );
                              }
                            }}
                            onKeyDown={(event) => {
                              handleSimulationGraphOnKeyDown(event, 0.0, 100.0);
                            }}
                          />

                          <Text className="value-text">m</Text>
                        </div>
                        <div className="value-layout">
                          <Text
                            className="value-label"
                            style={{ lineHeight: "20px", textAlign: "left" }}
                          >
                            Tracking Object Height
                          </Text>

                          <InputNumber
                            min={0.0}
                            max={100.0}
                            step={1} // Set the step to control decimal places
                            precision={2}
                            value={
                              lidarInfo?.simulationSettings
                                ?.tracked_object_height
                            }
                            onBlur={(event) => {
                              const value = Number(event.target.value);
                              if (
                                event.target.value !== "" &&
                                value >= 0.0 &&
                                value <= 100.0 &&
                                value >
                                  lidarInfo?.simulationSettings?.ground_offset
                              ) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    simulationSettings: {
                                      ...lidarInfo.simulationSettings,
                                      tracked_object_height: value,
                                    },
                                  })
                                );
                              } else if (event.target.value !== "") {
                                Modal.warning({
                                  content:
                                    "Tracking Object Height must be greater than Ground Offset",
                                  onOk() {},
                                });
                              }
                              toggleFlag();
                            }}
                            onStep={(value) => {
                              const currentValue =
                                parseFloat(
                                  lidarInfo?.simulationSettings
                                    ?.tracked_object_height
                                ) - value;
                              const newValue =
                                parseFloat(
                                  lidarInfo?.simulationSettings
                                    ?.tracked_object_height
                                ) - currentValue;

                              if (
                                newValue >= 0.0 &&
                                newValue <= 100.0 &&
                                value >
                                  lidarInfo?.simulationSettings?.ground_offset
                              ) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    simulationSettings: {
                                      ...lidarInfo.simulationSettings,
                                      tracked_object_height: newValue,
                                    },
                                  })
                                );
                              }
                            }}
                            onKeyDown={(event) => {
                              handleSimulationGraphOnKeyDown(event, 0.0, 100.0);
                            }}
                          />
                          <Text className="value-text">m</Text>
                        </div>
                      </div>
                    </div>

                    <div className="fields-outer-layout">
                      <div className="fileds-value-outer-layout">
                        <div className="value-layout">
                          <Text
                            className="value-label"
                            style={{ lineHeight: "20px", textAlign: "left" }}
                          >
                            Sample Size Length
                          </Text>
                          <InputNumber
                            min={0.01}
                            max={100.0}
                            step={1} // Set the step to control decimal places
                            precision={2}
                            value={
                              lidarInfo?.simulationSettings?.sample_size?.length
                            }
                            onBlur={(event) => {
                              const value = Number(event.target.value);

                              if (
                                event.target.value !== "" &&
                                value >= 0.01 &&
                                value <= 100.0
                              ) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    simulationSettings: {
                                      ...lidarInfo.simulationSettings,
                                      sample_size: {
                                        ...lidarInfo.simulationSettings
                                          .sample_size,
                                        length: value,
                                      },
                                    },
                                  })
                                );
                              }

                              toggleFlag();
                            }}
                            onStep={(value) => {
                              const currentValue =
                                parseFloat(
                                  lidarInfo?.simulationSettings?.sample_size
                                    ?.length
                                ) - value;
                              const newValue =
                                parseFloat(
                                  lidarInfo?.simulationSettings?.sample_size
                                    ?.length
                                ) - currentValue;

                              if (newValue >= 0.01 && newValue <= 100.0) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    simulationSettings: {
                                      ...lidarInfo.simulationSettings,
                                      sample_size: {
                                        ...lidarInfo.simulationSettings
                                          .sample_size,
                                        length: newValue,
                                      },
                                    },
                                  })
                                );
                              }
                            }}
                            onKeyDown={(event) => {
                              handleSimulationGraphOnKeyDown(event, 0.01, 100.0);
                            }}
                          />
                          <Text className="value-text">m</Text>
                        </div>
                        <div className="value-layout">
                          <Text
                            className="value-label"
                            style={{ lineHeight: "20px", textAlign: "left" }}
                          >
                            Sample Size Width
                          </Text>
                          <InputNumber
                            min={0.01}
                            max={100.0}
                            step={1} // Set the step to control decimal places
                            precision={2}
                            value={
                              lidarInfo?.simulationSettings?.sample_size?.width
                            }
                            onBlur={(event) => {
                              const value = Number(event.target.value);

                              if (
                                event.target.value !== "" &&
                                value >= 0.01 &&
                                value <= 100.0
                              ) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    simulationSettings: {
                                      ...lidarInfo.simulationSettings,
                                      sample_size: {
                                        ...lidarInfo.simulationSettings
                                          .sample_size,
                                        width: event.target.value,
                                      },
                                    },
                                  })
                                );
                              }
                              toggleFlag();
                            }}
                            onStep={(value) => {
                              const currentValue =
                                parseFloat(
                                  lidarInfo?.simulationSettings?.sample_size
                                    ?.width
                                ) - value;
                              const newValue =
                                parseFloat(
                                  lidarInfo?.simulationSettings?.sample_size
                                    ?.width
                                ) - currentValue;

                              if (newValue >= 0.01 && newValue <= 100.0) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    simulationSettings: {
                                      ...lidarInfo.simulationSettings,
                                      sample_size: {
                                        ...lidarInfo.simulationSettings
                                          .sample_size,
                                        width: newValue,
                                      },
                                    },
                                  })
                                );
                              }
                            }}
                            onKeyDown={(event) => {
                              handleSimulationGraphOnKeyDown(event, 0.01, 100.0);
                            }}
                          />
                          <Text className="value-text">m</Text>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="simulation-field">
                    <Text className="simulation-field-title">
                      {" "}
                      Beam Coverage Plot{" "}
                    </Text>

                    <div className="fields-outer-layout">
                      <div className="fileds-value-outer-layout">
                        <div className="value-layout">
                          <Checkbox
                            className="checkbox-fields"
                            checked={discreteColors}
                            onChange={() => {
                              dispatch(
                                setLidarInfo({
                                  ...lidarInfo,
                                  plotSettings: {
                                    ...lidarInfo.plotSettings,
                                    arrangement1: {
                                      ...lidarInfo.plotSettings.arrangement1,
                                      beam_coverage: {
                                        ...lidarInfo.plotSettings.arrangement1
                                          .beam_coverage,
                                        "discrete colors": discreteColors
                                          ? "false"
                                          : "true",
                                        range: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .beam_coverage.range,
                                          enabled: "false",
                                        },
                                      },
                                    },
                                  },
                                })
                              );

                              setDiscreteColors(!discreteColors);
                              setUseRangeBeamCoverage(false);
                              toggleFlag();
                            }}
                          >
                            Discrete Colors
                          </Checkbox>
                        </div>
                        <div className="value-layout">
                          <Text className="value-label">Minimum</Text>
                          <InputNumber
                            min={0}
                            max={1000}
                            formatter={(value) => `${parseInt(value, 10)}`} // Display only the integer part
                            parser={(value) => parseInt(value, 10)} // Parse the input as an integer
                            value={
                              lidarInfo?.plotSettings?.arrangement1
                                ?.beam_coverage?.range?.min
                            }
                            onBlur={(event) => {
                              const value = Number(event.target.value);

                              if (
                                event.target.value !== "" &&
                                value >= 0 &&
                                value <= 1000
                              ) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    plotSettings: {
                                      ...lidarInfo.plotSettings,
                                      arrangement1: {
                                        ...lidarInfo.plotSettings.arrangement1,
                                        beam_coverage: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .beam_coverage,
                                          range: {
                                            ...lidarInfo.plotSettings
                                              .arrangement1.beam_coverage.range,
                                            min: value,
                                          },
                                        },
                                      },
                                    },
                                  })
                                );
                              }

                              toggleFlag();
                            }}
                            onStep={(value) => {
                              const currentValue =
                                parseInt(
                                  lidarInfo?.plotSettings?.arrangement1
                                    ?.beam_coverage?.range?.min
                                ) - value;
                              const newValue =
                                parseInt(
                                  lidarInfo?.plotSettings?.arrangement1
                                    ?.beam_coverage?.range?.min
                                ) - currentValue;

                              if (newValue >= 0 && newValue <= 1000) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    plotSettings: {
                                      ...lidarInfo.plotSettings,
                                      arrangement1: {
                                        ...lidarInfo.plotSettings.arrangement1,
                                        beam_coverage: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .beam_coverage,
                                          range: {
                                            ...lidarInfo.plotSettings
                                              .arrangement1.beam_coverage.range,
                                            min: newValue,
                                          },
                                        },
                                      },
                                    },
                                  })
                                );
                              }
                            }}
                            onKeyDown={handleCoveragePlotOnKeyDown}
                            disabled={!useRangeBeamCoverage}
                            
                          />
                          <Text className="value-text">Beams</Text>
                        </div>
                      </div>
                    </div>

                    <div className="fields-outer-layout">
                      <div className="fileds-value-outer-layout">
                        <div className="value-layout">
                          <Checkbox
                            className="checkbox-fields"
                            checked={useRangeBeamCoverage}
                            onChange={() => {
                              dispatch(
                                setLidarInfo({
                                  ...lidarInfo,
                                  plotSettings: {
                                    ...lidarInfo.plotSettings,
                                    arrangement1: {
                                      ...lidarInfo.plotSettings.arrangement1,
                                      beam_coverage: {
                                        ...lidarInfo.plotSettings.arrangement1
                                          .beam_coverage,
                                        "discrete colors": "false",
                                        range: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .beam_coverage.range,
                                          enabled: useRangeBeamCoverage
                                            ? "false"
                                            : "true",
                                        },
                                      },
                                    },
                                  },
                                })
                              );

                              setUseRangeBeamCoverage(!useRangeBeamCoverage);
                              setDiscreteColors(false);
                              toggleFlag();
                            }}
                          >
                            Use Range
                          </Checkbox>
                        </div>
                        <div className="value-layout">
                          <Text className="value-label">Maximum</Text>
                          <InputNumber
                            min={0}
                            max={1000}
                            formatter={(value) => `${parseInt(value, 10)}`} // Display only the integer part
                            parser={(value) => parseInt(value, 10)}
                            value={
                              lidarInfo?.plotSettings?.arrangement1
                                ?.beam_coverage?.range?.max
                            }
                            onBlur={(event) => {
                              const value = Number(event.target.value);

                              if (
                                event.target.value !== "" &&
                                value >= 0 &&
                                value <= 1000
                              ) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    plotSettings: {
                                      ...lidarInfo.plotSettings,
                                      arrangement1: {
                                        ...lidarInfo.plotSettings.arrangement1,
                                        beam_coverage: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .beam_coverage,
                                          range: {
                                            ...lidarInfo.plotSettings
                                              .arrangement1.beam_coverage.range,
                                            max: value,
                                          },
                                        },
                                      },
                                    },
                                  })
                                );
                              }

                              toggleFlag();
                            }}
                            disabled={!useRangeBeamCoverage}
                            onStep={(value) => {
                              const currentValue =
                                parseInt(
                                  lidarInfo?.plotSettings?.arrangement1
                                    ?.beam_coverage?.range?.max
                                ) - value;
                              const newValue =
                                parseInt(
                                  lidarInfo?.plotSettings?.arrangement1
                                    ?.beam_coverage?.range?.max
                                ) - currentValue;

                              if (newValue >= 0 && newValue <= 1000) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    plotSettings: {
                                      ...lidarInfo.plotSettings,
                                      arrangement1: {
                                        ...lidarInfo.plotSettings.arrangement1,
                                        beam_coverage: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .beam_coverage,
                                          range: {
                                            ...lidarInfo.plotSettings
                                              .arrangement1.beam_coverage.range,
                                            max: newValue,
                                          },
                                        },
                                      },
                                    },
                                  })
                                );
                              }
                            }}
                            onKeyDown={handleCoveragePlotOnKeyDown}
                          />
                          <Text className="value-text">Beams</Text>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simulation-field">
                    <Text className="simulation-field-title">
                      Point Coverage Plot
                    </Text>

                    <div className="fields-outer-layout">
                      <div className="fileds-value-outer-layout">
                        <div className="value-layout">
                          <Checkbox
                            className="checkbox-fields"
                            checked={discreteColorsPointCoverage}
                            onChange={() => {
                              dispatch(
                                setLidarInfo({
                                  ...lidarInfo,
                                  plotSettings: {
                                    ...lidarInfo.plotSettings,
                                    arrangement1: {
                                      ...lidarInfo.plotSettings.arrangement1,
                                      point_coverage: {
                                        ...lidarInfo.plotSettings.arrangement1
                                          .point_coverage,
                                        "discrete colors":
                                          discreteColorsPointCoverage
                                            ? "false"
                                            : "true",
                                        range: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .point_coverage.range,
                                          enabled: "false",
                                        },
                                      },
                                    },
                                  },
                                })
                              );

                              setDiscreteColorsPointCoverage(
                                !discreteColorsPointCoverage
                              );
                              setUseRangePointCoverage(false);
                              toggleFlag();
                            }}
                          >
                            Discrete Colors
                          </Checkbox>
                        </div>
                        <div className="value-layout">
                          <Text className="value-label">Minimum</Text>
                          <InputNumber
                            min={0}
                            max={1000}
                            formatter={(value) => `${parseInt(value, 10)}`} // Display only the integer part
                            parser={(value) => parseInt(value, 10)}
                            value={
                              lidarInfo?.plotSettings?.arrangement1
                                ?.point_coverage?.range?.min
                            }
                            onBlur={(event) => {
                              const value = Number(event.target.value);
                              if (
                                event.target.value !== "" &&
                                value >= 0 &&
                                value <= 1000
                              ) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    plotSettings: {
                                      ...lidarInfo.plotSettings,
                                      arrangement1: {
                                        ...lidarInfo.plotSettings.arrangement1,
                                        point_coverage: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .point_coverage,
                                          range: {
                                            ...lidarInfo.plotSettings
                                              .arrangement1.point_coverage
                                              .range,
                                            min: value,
                                          },
                                        },
                                      },
                                    },
                                  })
                                );
                              }

                              toggleFlag();
                            }}
                            disabled={!useRangePointCoverage}
                            onStep={(value) => {
                              const currentValue =
                                parseFloat(
                                  lidarInfo?.plotSettings?.arrangement1
                                    ?.point_coverage?.range?.min
                                ) - value;
                              const newValue =
                                parseFloat(
                                  lidarInfo?.plotSettings?.arrangement1
                                    ?.point_coverage?.range?.min
                                ) - currentValue;

                              if (newValue >= 0 && newValue <= 1000) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    plotSettings: {
                                      ...lidarInfo.plotSettings,
                                      arrangement1: {
                                        ...lidarInfo.plotSettings.arrangement1,
                                        point_coverage: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .point_coverage,
                                          range: {
                                            ...lidarInfo.plotSettings
                                              .arrangement1.point_coverage
                                              .range,
                                            min: newValue,
                                          },
                                        },
                                      },
                                    },
                                  })
                                );
                              }
                            }}
                            onKeyDown={handleCoveragePlotOnKeyDown}
                          />
                          <Text className="value-text">Points</Text>
                        </div>
                      </div>
                    </div>

                    <div className="fields-outer-layout">
                      <div className="fileds-value-outer-layout">
                        <div className="value-layout">
                          <Checkbox
                            className="checkbox-fields"
                            checked={useRangePointCoverage}
                            onChange={() => {
                              dispatch(
                                setLidarInfo({
                                  ...lidarInfo,
                                  plotSettings: {
                                    ...lidarInfo.plotSettings,
                                    arrangement1: {
                                      ...lidarInfo.plotSettings.arrangement1,
                                      point_coverage: {
                                        ...lidarInfo.plotSettings.arrangement1
                                          .point_coverage,
                                        "discrete colors": "false",
                                        range: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .point_coverage.range,
                                          enabled: useRangePointCoverage
                                            ? "false"
                                            : "true",
                                        },
                                      },
                                    },
                                  },
                                })
                              );
                              setUseRangePointCoverage(!useRangePointCoverage);
                              setDiscreteColorsPointCoverage(false);
                              toggleFlag();
                            }}
                          >
                            Use Range
                          </Checkbox>
                        </div>
                        <div className="value-layout">
                          <Text className="value-label">Maximum</Text>
                          <InputNumber
                            min={0}
                            max={1000}
                            formatter={(value) => `${parseInt(value, 10)}`} // Display only the integer part
                            parser={(value) => parseInt(value, 10)}
                            value={
                              lidarInfo?.plotSettings?.arrangement1
                                ?.point_coverage?.range?.max
                            }
                            onBlur={(event) => {
                              const value = Number(event.target.value);

                              if (
                                event.target.value !== "" &&
                                value >= 0 &&
                                value <= 1000
                              ) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    plotSettings: {
                                      ...lidarInfo.plotSettings,
                                      arrangement1: {
                                        ...lidarInfo.plotSettings.arrangement1,
                                        point_coverage: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .point_coverage,
                                          range: {
                                            ...lidarInfo.plotSettings
                                              .arrangement1.point_coverage
                                              .range,
                                            max: value,
                                          },
                                        },
                                      },
                                    },
                                  })
                                );
                              }

                              toggleFlag();
                            }}
                            onStep={(value) => {
                              const currentValue =
                                parseInt(
                                  lidarInfo?.plotSettings?.arrangement1
                                    ?.point_coverage?.range?.max
                                ) - value;
                              const newValue =
                                parseInt(
                                  lidarInfo?.plotSettings?.arrangement1
                                    ?.point_coverage?.range?.max
                                ) - currentValue;

                              if (newValue >= 0 && newValue <= 1000) {
                                dispatch(
                                  setLidarInfo({
                                    ...lidarInfo,
                                    plotSettings: {
                                      ...lidarInfo.plotSettings,
                                      arrangement1: {
                                        ...lidarInfo.plotSettings.arrangement1,
                                        point_coverage: {
                                          ...lidarInfo.plotSettings.arrangement1
                                            .point_coverage,
                                          range: {
                                            ...lidarInfo.plotSettings
                                              .arrangement1.point_coverage
                                              .range,
                                            max: newValue,
                                          },
                                        },
                                      },
                                    },
                                  })
                                );
                              }
                            }}
                            onKeyDown={handleCoveragePlotOnKeyDown}
                            disabled={!useRangePointCoverage}
                          />
                          <Text className="value-text">Points</Text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SideBar;
