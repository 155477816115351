// Api.js
import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: BASE_URL,
});


const addAuthTokenHeader = () => {
  const authToken = Cookies.get("authToken");

  if (authToken) {
    return {
      headers: {
        Authorization: `Bearer ${authToken}`
              },
    };
  }
  return {};
};

async function refreshTokenApi() {
  try {
    const refToken = await instance.post(
      "generatetoken/",
      { user_id: JSON.parse(Cookies.get("profileData")).userId },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("refreshToken")}`,
        },
      }
    );
    Cookies.set("authToken", refToken.data.access_token);
    return {
      access_token: refToken.data.access_token,
      status: refToken.status,
    };
  } catch (error) {
    Cookies.remove("profileData");
    Cookies.remove("authToken");
    Cookies.remove("refreshToken");
    window.location.href = "/login";
    return error;
  }
}

const handleAuthRequest = async (requestFunction, ...args) => {
  try {
    const response = await requestFunction(...args, addAuthTokenHeader());
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      try {
        const getRefreshToken = await refreshTokenApi();

        if (getRefreshToken.status !== 401) {
          return await requestFunction(...args, addAuthTokenHeader());
        }
      } catch (error) {
        return error;
      }
    }
    throw error.response ? error.response.data : error;
  }
};

export const AuthGetData = async (endpoint) => {
  return await handleAuthRequest(instance.get, endpoint);
};

export const PostData = async (endpoint, body) => {
  try {
    const response = await instance.post(`${endpoint}`, body);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const AuthPostData = async (endpoint, body) => {
  return await handleAuthRequest(instance.post, endpoint, body);
};

export const AuthDeleteData = async (endpoint, data) => {
  return await handleAuthRequest(instance.delete, `/${endpoint}`, {
    ...addAuthTokenHeader(),
    data,
  });
};
