import defaultjson from "../data/DefaultJSON";
import { createSlice } from "@reduxjs/toolkit";

const LidarSlice = createSlice({
  name: "lidar",
  initialState: defaultjson,
  reducers: {
    setLidarInfo: (state, action) => {
      return action.payload;
    },
  },
});

export const { setLidarInfo } = LidarSlice.actions;
export const selectLidarInfo = (state) => state.lidar;
export default LidarSlice.reducer;
