export const endPoints = {
    login: "login/",
    verifyOtp: "verifyotp/",
    resendOtp: "resendotp/",
    isFilePlotGenerated: "isFilePlotGenerated/",
    isPlotGenerated: "isPlotGenerated/",
    getSensorData: "getsensordata/",
    generate: "generate/",
    plotArray: "plotArray/",
    getUserFiles: "getuserfiles/",
    uploadFiles: "upload-files/",
    sensorData: "sensordata/",
    getS3Url: "get-s3-url/",
    requestAccountDeactivation: "request-account-deactivation/"
  };
  