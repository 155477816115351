import React, { useEffect, useState } from "react";
import {
  HomeOutlined,
  DownloadOutlined,
  EditOutlined,
  CaretDownOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import {
  Typography,
  Button,
  Upload,
  Tooltip,
  message,
  Spin,
  Input,
  Dropdown,
  Menu,
} from "antd";
import "./GraphPlotHeader.css";
import { useNavigate } from "react-router-dom";
import { selectLidarInfo, setLidarInfo } from "../redux/LidarSlice";
import { useSelector, useDispatch } from "react-redux";
import { AuthPostData } from "../services/Api";
import { endPoints } from "../services/EndPoints";
import Cookies from "js-cookie";
import ConfigurationImageReference from "./ConfigurationImageReference";
const { Text } = Typography;

function GraphPlotHeader({
  fileId,
  handleUnsavedChanges,
  filename,
  loadPyodide,
  toggleFlag,
  onImageUpdate,
  isAdjustButtonEnable,
  adjustImage,
  screenDisable,
  handleAllFilesClick,
  updateUnsavedChanges,
  handleFileNameChange
}) {
  const navigate = useNavigate();
  const lidarInfo = useSelector(selectLidarInfo);
  const dispatch = useDispatch();
  const [fileID, setFileID] = useState(fileId);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newFileName, setNewFileName] = useState();
  const [image, setImage] = useState(null);
  const [importImageModelVisible, setImportImageModelVisible] = useState(false);
  const [adjustImageModelVisible, setAdjustImageModelVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);


  const allowedImageExtensions = [
    "png",
    "jpg",
    "jpeg",
    "jpe",
    "tif",
    "tiff",
    "gif",
    "pnm",
    "ppm",
    "pgm",
    "pbm",
    "pcx",
    "iff",
    "ico",
    "cur",
    "ani",
    "tga",
    "tpic",
    "xpm",
    "bmp",
  ];

  const isImageExtensionAllowed = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    return allowedImageExtensions.includes(extension);
  };

  const handleBeforeUploadImage = (file) => {
    const isImage = isImageExtensionAllowed(file.name);

    if (!isImage) {
      message.error("You can only upload specific image files!");
    }

    return isImage;
  };

  const handleImportImageModelOk = (loc) => {
    // Handle any actions when the modal is confirmed

    dispatch(
      setLidarInfo({
        ...lidarInfo,
        plotSettings: {
          ...lidarInfo.plotSettings,
          "reference image": {
            ...lidarInfo.plotSettings["reference image"],

            origin: {
              x: loc.origin_x,
              y: loc.origin_y,
            },
            rotation: loc.rotation,
            scale: loc.scale,
            alpha: loc.alpha,
          },
        },
      })
    );

    setImportImageModelVisible(false);
    onImageUpdate(image);
    toggleFlag();
  };

  const handleImportImageModelCancel = () => {
    setImportImageModelVisible(false);
    updateUnsavedChanges(0);
    toggleFlag();
  };

  const handleAdjustImageModalOk = (loc) => {
    dispatch(
      setLidarInfo({
        ...lidarInfo,
        plotSettings: {
          ...lidarInfo.plotSettings,
          "reference image": {
            ...lidarInfo.plotSettings["reference image"],

            origin: {
              x: loc.origin_x,
              y: loc.origin_y,
            },
            rotation: loc.rotation,
            scale: loc.scale,
            alpha: loc.alpha,
          },
        },
      })
    );

    setAdjustImageModelVisible(false);
    toggleFlag();
  };

  const handleAdjustImageModalCancel = () => {
    setAdjustImageModelVisible(false);
    updateUnsavedChanges(0);
    toggleFlag();
  };

  const handleUploadImage = ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const imageData = event.target.result;
      setImage(imageData);
      setImportImageModelVisible(true);
      onSuccess();
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const fileName = filename.length !== 0 ? filename : "Graphplot";
    setNewFileName(fileName);
  }, [filename]);

  const handleImageSave = async (userId, fileID) => {
    if (image) {
      try {
        const blob = await fetch(image).then((r) => r.blob());
        const binaryData = await new Response(blob).arrayBuffer();
        const referenceImageFileName = `${userId}/saveReferenceImage_${fileID}.png`;
        const putUrl = await AuthPostData(endPoints.uploadFiles, {
          file: `sct/dev/${referenceImageFileName}`,
        }); // Assuming this function returns the URL for uploading
        await fetch(putUrl.url, {
          method: "PUT", // or 'POST' depending on your server's requirements
          body: binaryData,
          headers: {
            "Content-Type": "application/octet-stream", // Set to binary data
          },
        });

        // Return the updated lidarInfo structure without dispatching
        return {
          ...lidarInfo,
          plotSettings: {
            ...lidarInfo.plotSettings,
            "reference image": {
              ...lidarInfo.plotSettings["reference image"],
              filename: `sct/dev/${referenceImageFileName}`,
            },
          },
        };
      } catch (error) {
        message.error("Failed to save image data");
        return lidarInfo; // Indicate failure by returning null or some other value
      }
    } else {
      const referenceImageFilename =
        lidarInfo.plotSettings["reference image"].filename;

      const regex = /(sct\/.*)/;
      const match = referenceImageFilename.match(regex);

      if (match) {
        const result = match[1]; // Group 1 contains "sct/" and the part after it

        return {
          ...lidarInfo,
          plotSettings: {
            ...lidarInfo.plotSettings,
            "reference image": {
              ...lidarInfo.plotSettings["reference image"],
              filename: result,
            },
          },
        };
      }
      return lidarInfo;
    }
    // If there is no image, return the original lidarInfo
  };

  async function handleSaveButton() {
    let profileData = Cookies.get("profileData");
    profileData = JSON.parse(profileData);

    if (profileData) {

      setSaveButtonDisabled(true);

      if (!newFileName.trim()) {
        message.error("File name should not be empty");
        setSaveButtonDisabled(false);
        return;
      }

      if (newFileName.length > 220) {
        message.error("File name size is more than 220 characters");
        setSaveButtonDisabled(false);
        return;
      }

      const userFiles = await AuthPostData(endPoints.getUserFiles, {
        email: profileData.email,
      });

      const fileNameExists = Object.values(userFiles.data).some((item) => {
        return item.file === newFileName && item.id !== fileID;
      });

      if (fileNameExists) {
        message.error(
          "File name already exists. Please choose a different name"
        );
        setSaveButtonDisabled(false);
        return;
      }

      if (fileID) {
        try {
          setLoading(true);
          const updatedLidarInfo = await handleImageSave(
            profileData.userId,
            fileID
          );

          dispatch(setLidarInfo(updatedLidarInfo));

          const result = await AuthPostData(endPoints.sensorData, {
            email: profileData.email,
            userFileId: fileID,
            fileName: newFileName,
            ...updatedLidarInfo,
          });

          message.success(result.msg);
          handleFileNameChange(newFileName);
        } catch (errors) {
          const error =
            errors.error ||
            "Unable to connect with the server, please try again after some time";
          message.error(error);
        } finally {
          setLoading(false);
          handleUnsavedChanges(false);
          setEditing(false);
          setSaveButtonDisabled(false);
        }
      } else {
        try {
          setLoading(true);
          const result = await AuthPostData(endPoints.sensorData, {
            email: profileData.email,
            fileName: newFileName,
            ...lidarInfo,
          });
          localStorage.setItem("fileId", result.userFileId);
          setFileID(result.userFileId);

          if (image !== "") {
            const updatedLidarInfo = await handleImageSave(
              profileData.userId,
              result.userFileId
            );

            dispatch(setLidarInfo(updatedLidarInfo));
            await AuthPostData(endPoints.sensorData, {
              email: profileData.email,
              fileName: newFileName,
              userFileId: result.userFileId,
              ...updatedLidarInfo,
            });
          }

          handleFileNameChange(newFileName);
          message.success(result.msg);
        } catch (errors) {
          const error =
            errors.error ||
            "Unable to connect with the server, please try again after some time";
          message.error(error);
        } finally {
          setLoading(false);
          handleUnsavedChanges(false);
          setEditing(false);
          setSaveButtonDisabled(false);
        }
      }
    } else {
      message.error("Something went wrong please relogin");
      navigate("login");
    }
  }

  const handleMenuClick = ({ key }) => {
    setVisible(false);
  };

  const handleAdjustButtonClick = () => {
    setAdjustImageModelVisible(true);
  };
  const menu = (
    <Menu onClick={handleMenuClick} className="menu-items-style1">
      <Menu.Item
        key="item1"
        className="menu-items-style"
        style={{ padding: "0px" }}
      >
        <div className="import-image-button">
          <Upload
            customRequest={handleUploadImage}
            showUploadList={false}
            beforeUpload={handleBeforeUploadImage}
          >
            <Button className="import-image-button" icon={<DownloadOutlined />}>
              Import
            </Button>
          </Upload>
        </div>
      </Menu.Item>
      <Menu.Item
        key="item2"
        className="menu-items-style"
        style={{ padding: "0px" }}
      >
        <Button
          className="import-image-button"
          disabled={isAdjustButtonEnable}
          onClick={handleAdjustButtonClick}
          icon={<SlidersOutlined />}
        >
          Adjust
        </Button>
      </Menu.Item>
    </Menu>
  );

  const truncateString = (str, maxChars) => {
    if (str) {
      if (str.length <= maxChars) {
        return str;
      }

      const truncated = str.substring(0, maxChars - 1) + "...";
      return truncated;
    }
  };

  const handleNewFileName = (event) => {
    const value = event.target.value;
    setNewFileName(value);
  };


  return (
    <>
      <div
        className="loader-container"
        style={{ display: loading ? "flex" : "none" }}
      >
        <Spin size="large" tip="Loading">
          <span></span>
        </Spin>
      </div>
      <div className="header-outer-layout">
        <div className="header-left-side-outer-layout">
          <HomeOutlined className="home-outlined" />
          <span
            onClick={(event) => {
              event.stopPropagation();
              handleAllFilesClick();
            }}
          >
            {" "}
            <Text className="header-text-style" style={{ cursor: "pointer" }}>
              All Files
            </Text>
          </span>

          <Text className="header-text-style">/</Text>

          {editing ? (
            <Input
              maxLength={220}
              minLength={1}
              value={newFileName}
              className="header-file-name-text-style"
              onChange={handleNewFileName}

            />
          ) : (
            <Tooltip title={newFileName}>
              <Text className="header-text-style" style={{ color: "#FFF" }}>
                {truncateString(newFileName, 40)}
              </Text>
            </Tooltip>
          )}

          <EditOutlined
            className={`edit-outline-icon ${screenDisable ? "disabled" : ""}`}
            onClick={() => !screenDisable && setEditing(true)}
            display={screenDisable}
          ></EditOutlined>
        </div>

        <div className="header-right-side-outer-layout">
          <Dropdown
            disabled={screenDisable}
            overlay={menu}
            open={visible}
            onOpenChange={(value) => setVisible(value)}
            trigger={["click"]}
          >
            <div
              onClick={(event) => event.preventDefault()}
              className={`refrence-image-text ${
                screenDisable ? "disabled" : ""
              }`}
            >
              Reference Image <CaretDownOutlined />
            </div>
          </Dropdown>

          <Tooltip title="Save the configuration on the online account">
            <Button
              className="save-button"
              onClick={handleSaveButton}
              disabled={screenDisable || saveButtonDisabled}
            >
              Save
            </Button>
          </Tooltip>

          {importImageModelVisible && (
            <ConfigurationImageReference
              visible={importImageModelVisible}
              onCancel={handleImportImageModelCancel}
              onOk={handleImportImageModelOk}
              image={image}
              loadPyodide={loadPyodide}
              fileName={filename}
            ></ConfigurationImageReference>
          )}

          {adjustImageModelVisible && (
            <ConfigurationImageReference
              visible={adjustImageModelVisible}
              onCancel={handleAdjustImageModalCancel}
              onOk={handleAdjustImageModalOk}
              image={adjustImage}
              loadPyodide={loadPyodide}
              fileName={filename}
            ></ConfigurationImageReference>
          )}
        </div>
      </div>
    </>
  );
}

export default GraphPlotHeader;
